import {
    AccordionSummary,
    AccordionSummaryProps,
    styled,
    Typography
} from '@mui/material'

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(2)
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
        color: theme.palette.text.primary,
        fontSize: '2.5rem'
    }
}))

export const LeafAccordionSummary: React.FC<AccordionSummaryProps> = ({
    children,
    ...props
}) => {
    return (
        <StyledAccordionSummary {...props}>
            <Typography variant="h5" component="p">
                {children}
            </Typography>
        </StyledAccordionSummary>
    )
}
export default LeafAccordionSummary
