import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import LeafBasePolicyLink from '../LeafBasePolicyLink'
import { localeToToSMap } from '../LeafBasePolicyLink/constants/maps'
import { LeafExternalLinkProps } from '../../LeafExternalLink'

export const policyMessages = defineMessages({
    termsOfService: {
        defaultMessage: 'Terms of Service',
        description: 'Link to our Terms of Service'
    }
})

const LeafToSLink: React.FC<LeafExternalLinkProps> = ({ ...props }) => {
    const intl = useIntl()

    return (
        <LeafBasePolicyLink
            linkText={intl.formatMessage(policyMessages.termsOfService)}
            linkMap={localeToToSMap}
            {...props}
        />
    )
}

export default LeafToSLink
