import { ExtendedThemeOptions } from '../theme'
import { darkShadows } from '../shadows'
import ThriveLogoSingleLeaf from './themeLogos/ThriveLogoSingleLeaf'

export const darkTheme: ExtendedThemeOptions = {
    Logo: ThriveLogoSingleLeaf,
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                }
            }
        }
    },
    palette: {
        secondary: {
            main: '#5D638B',
            dark: '#D4D2EE',
            light: '#efeff8',
            contrastText: '#ffffff'
        },
        primary: {
            main: '#FFFFFF',
            dark: '#D4D2EE',
            light: '#D7C2FF',
            contrastText: '#1E0056'
        },
        accent: {
            main: '#1EA8BE',
            dark: '#035A66',
            light: '#E8F4F5',
            contrastText: '#ffffff'
        },
        mode: 'dark',
        action: {
            active: '#6b778c',
            focus: 'blue'
        },
        background: {
            default: '#2f2f2f',
            paper: '#121212'
        },
        text: {
            primary: '#ffffff',
            secondary: '#DBDDE0'
        },
        info: {
            main: '#121212',
            dark: '#121212',
            light: '#121212',
            contrastText: '#BD99FF'
        },
        error: {
            main: '#DE7C81',
            dark: '#D8766F',
            light: '#3D0005',
            contrastText: '#ffffff'
        },
        warning: {
            main: '#F6C344',
            dark: '#663C00',
            light: '#FBF9F2',
            contrastText: '#ffffff'
        },
        success: {
            main: '#00C2AB',
            dark: '#46AA93',
            light: '#0A1E1C',
            contrastText: '#ffffff'
        },
        divider: '#F7F7F8',
        grey: {
            50: '#172235',
            100: '#343E4E',
            200: '#4F5865',
            300: '#868B95',
            400: '#868B95',
            500: '#A2A6AE',
            600: '#BFC2C7',
            700: '#DBDDE0',
            800: '#F7F7F8',
            900: '#FBFBFB'
        },
        journeys: {
            recharge: {
                main: '#BA8CFF',
                dark: '#9570CC',
                light: '#F0E6FE',
                contrastText: '#172235'
            },
            focus: {
                main: '#5DBFF0',
                dark: '#4A99C0',
                light: '#E6F5FE',
                contrastText: '#172235'
            },
            move: {
                main: '#F26B3D',
                dark: '#C25631',
                light: '#FEE6E6',
                contrastText: '#172235'
            },
            food: {
                main: '#FFB331',
                dark: '#CC8F27',
                light: '#FDF9E4',
                contrastText: '#172235'
            },
            connect: {
                main: '#FC72A5',
                dark: '#CA5B83',
                light: '#FEE6F9',
                contrastText: '#172235'
            },
            money: {
                main: '#60BF70',
                dark: '#56AC65',
                light: '#E6FEEB',
                contrastText: '#172235'
            },
            stressManagement: {
                main: '#39C6B8',
                dark: '#26A79A',
                light: '#EAFFFD',
                contrastText: '#172235'
            }
        }
    },
    gradient: {
        light: '#2f2f2f',
        main: '#2f2f2f'
    },
    videoTextProtectionOverlay: '#000000BF',
    shadows: darkShadows
}
