import { Box } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { useTheme } from '../../../../hooks'

export type PlayerContainerProps = PropsWithChildren<{
    height?: string | number
    width?: string | number
    background?: string
    className?: string
}>

function PlayerContainer(props: PlayerContainerProps): JSX.Element {
    const { width, height, className, background, children } = props

    const theme = useTheme()

    return (
        <Box
            sx={{
                height: height ?? '100%',
                width: width ?? '100%',
                background: background ?? theme.palette.common.black
            }}
            className={className}
            data-testid="PlayerContainer"
        >
            {children}
        </Box>
    )
}

export default React.memo(PlayerContainer)
