import React from 'react'
import { Box } from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    fullLogoWhiteOnDark: {
        defaultMessage: "White leaf to the left of the word 'Thrive'.",
        description: 'Alt text for Thrive logo.'
    },
    fullLogoFlatOnDark: {
        defaultMessage: "Teal leaf to the left of the word 'Thrive'.",
        description: 'Alt text for Thrive logo.'
    },
    fullLogoMultiOnDark: {
        defaultMessage:
            "Multi-colored teal leaf to the left of the word 'Thrive'.",
        description: 'Alt text for Thrive logo.'
    },
    fullLogoMulti: {
        defaultMessage:
            "Multi-colored teal leaf to the left of the word 'Thrive'.",
        description: 'Alt text for Thrive logo.'
    }
})

export const FullLogoWhiteOnDark = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="fullLogoWhiteOnDark">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-FullLogo-1Color-OnDark.svg`}
                alt={intl.formatMessage(messages.fullLogoWhiteOnDark)}
            />
        </Box>
    )
}

export const FullLogoFlatOnDark = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="fullLogoFlatOnDark">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-FullLogo-Flat-OnDark.svg`}
                alt={intl.formatMessage(messages.fullLogoFlatOnDark)}
            />
        </Box>
    )
}

export const FullLogoMultiOnDark = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="fullLogoMultiOnDark">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-FullLogo-Multi-OnDark.svg`}
                alt={intl.formatMessage(messages.fullLogoMultiOnDark)}
            />
        </Box>
    )
}

export const FullLogoMulti = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="fullLogoMulti">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-FullLogo-Multi-OnLight.svg`}
                alt={intl.formatMessage(messages.fullLogoMulti)}
            />
        </Box>
    )
}
