import { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { Box, BoxProps } from '@mui/material'
import { imageConfig } from '../utils'
import useOnScreen from '../../../../hooks/useIsVisible'
import { captureException } from '@thriveglobal/thrive-web-core'
import useImageSizing from '../../../../hooks/useImageSizing'

export interface LeafImageBackgroundProps extends BoxProps {
    forceLoad?: boolean
    maxWidth?: number | undefined
    src: string
}

export const LeafImageBackgroundLazy: React.FC<LeafImageBackgroundProps> = ({
    forceLoad = false,
    maxWidth = undefined,
    src,
    className,
    children,
    ...props
}) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(ref)
    const { srcMain } = useImageSizing({ src, maxWidth })

    const handleError = useCallback(() => {
        captureException(new Error(`Failed to load image: ${srcMain}`))
        setImageLoaded(false)
    }, [srcMain])

    const handleLoad = useCallback(() => {
        setImageLoaded(true)
    }, [])

    useEffect(() => {
        if (!isVisible || imageLoaded) return

        const img = new window.Image()
        img.src = srcMain
        img.onload = handleLoad
        img.onerror = handleError

        return () => {
            delete img.onload
            delete img.onerror
        }
    }, [isVisible, imageLoaded, srcMain, handleLoad, handleError])

    return (
        <Box
            ref={ref}
            {...props}
            className={className}
            sx={{
                ...props.sx,
                backgroundImage: `url(${
                    imageLoaded || forceLoad
                        ? srcMain
                        : imageConfig.placeholderImage
                })`
            }}
        >
            {children}
        </Box>
    )
}

export const LeafImageBackground: React.FC<
    Omit<LeafImageBackgroundProps, 'forceLoad'>
> = (props) => <LeafImageBackgroundLazy {...props} forceLoad={true} />
