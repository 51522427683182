import { RefObject, useEffect } from 'react'

export default function useProperty<T, Key extends keyof T>(
    name: Key,
    ref: RefObject<T | undefined>,
    value: T[Key]
) {
    useEffect(() => {
        if (!ref.current) return
        const el = ref.current
        el[name] = value
    }, [name, value, ref])
}
