import React, { SyntheticEvent } from 'react'
import { Modal, Box } from '@mui/material'
import ClosePlayerPopupButton from '../ClosePlayerPopupButton'

export type PlayerPopupProps = {
    isOpen: boolean
    onClose: (
        event: SyntheticEvent,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => void
    children: React.ReactElement
}

function PlayerPopup(props: PlayerPopupProps) {
    const { isOpen, onClose, children } = props

    return (
        <Modal open={isOpen} onClose={onClose} data-testid="PlayerPopup">
            <Box sx={{ height: '100%', width: '100%' }}>
                <ClosePlayerPopupButton onClick={onClose} tabIndex={-2} />
                {children}
            </Box>
        </Modal>
    )
}

export default React.memo(PlayerPopup)
