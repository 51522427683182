import { ReactElement } from 'react'
import { Card, CardActions, CardContent, useTheme } from '@mui/material'

interface LeafTextCardProps {
    header?: string | ReactElement
    content: string | ReactElement
    actions?: ReactElement
    color?: 'light' | 'main' | 'dark'
}

const LeafTextCard: React.FC<LeafTextCardProps> = ({
    header,
    content,
    actions,
    color = 'light'
}) => {
    const theme = useTheme()
    const cardTheme = (color) => {
        switch (color) {
            case 'light':
                return {
                    bgcolor: theme.palette.common.white,
                    color: theme.palette.text.primary
                }
            case 'main':
                return {
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                }
            case 'dark':
                return {
                    bgcolor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText
                }
        }
    }

    return (
        <Card
            sx={{
                minWidth: 275,
                padding: 2,
                ...cardTheme(color)
            }}
        >
            <CardContent>
                {header}
                {content}
            </CardContent>
            {actions && (
                <CardActions
                    sx={{ justifyContent: 'space-between', padding: 2 }}
                >
                    {actions}
                </CardActions>
            )}
        </Card>
    )
}

export default LeafTextCard
