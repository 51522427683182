import { SvgIcon } from '@mui/material'

const Journeys = ({ ...props }) => {
    return (
        <SvgIcon
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.82135 14C1.82135 7.41041 7.13948 2.06944 13.6986 2.06944C20.2576 2.06944 25.5758 7.41041 25.5758 14C25.5758 20.5896 20.2576 25.9306 13.6986 25.9306C7.13948 25.9306 1.82135 20.5896 1.82135 14ZM13.6986 0.375C6.20616 0.375 0.133301 6.47564 0.133301 14C0.133301 21.5244 6.20616 27.625 13.6986 27.625C21.191 27.625 27.2638 21.5244 27.2638 14C27.2638 6.47564 21.191 0.375 13.6986 0.375Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.0266 10.0605L19.0266 10.0605C19.1191 9.82592 19.1422 9.56689 19.0929 9.3181C19.0461 9.08182 18.936 8.8649 18.7758 8.69378C18.6263 8.52025 18.4337 8.39398 18.2193 8.33039C17.9931 8.26334 17.7535 8.26919 17.5305 8.34714C17.5107 8.35408 17.4911 8.36195 17.472 8.37073L6.88567 13.2225C6.8855 13.2226 6.88533 13.2227 6.88515 13.2227C6.65082 13.3278 6.45766 13.514 6.33604 13.7501C6.21587 13.9833 6.17215 14.2522 6.21113 14.5142C6.24384 14.7599 6.34316 14.9906 6.49761 15.1775C6.65516 15.3682 6.86309 15.5049 7.09513 15.5689L7.09545 15.569L7.09815 15.5697L7.09845 15.5698L11.2727 16.6989L12.321 21.1966L12.3209 21.1966L12.3213 21.1982L12.3235 21.2068C12.384 21.4518 12.511 21.6728 12.6891 21.842C12.8646 22.0086 13.0822 22.1176 13.3157 22.1549C13.5651 22.1981 13.8207 22.1505 14.0413 22.0202C14.2641 21.8886 14.4374 21.6808 14.5344 21.4318C14.5344 21.4316 14.5345 21.4315 14.5345 21.4314L19.0266 10.0605ZM17.5462 9.95913L13.521 20.1482L12.5326 15.9078C12.4714 15.6451 12.2791 15.4339 12.0252 15.3652L8.07922 14.2979L17.5462 9.95913Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    )
}

export default Journeys
