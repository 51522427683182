import HLSPlayer, { HLSPlayerProps } from './HLSPlayer'
import IframePlayer, { IframePlayerProps } from './IframePlayer'
import IframePlayerWithHLSFallback, {
    IframePlayerWithHLSFallbackProps
} from './IframePlayerWithHLSFallback'
import Player from './Player'
import useIframePlayerSrc, {
    UseIframePlayerSrcOptions
} from './useIframePlayerSrc'
import { withStreamVolume } from './withStreamVolume'

export {
    HLSPlayer, // TODO: Replace with a lazy-loaded version of HLSPlayer
    IframePlayer,
    IframePlayerWithHLSFallback,
    Player,
    useIframePlayerSrc,
    withStreamVolume,
    type HLSPlayerProps,
    type IframePlayerProps,
    type IframePlayerWithHLSFallbackProps,
    type UseIframePlayerSrcOptions
}

// This version of player does not support stream from http://video.thriveglobal.com/
export default Player
