import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react'
import { StreamPlayerApi } from '@cloudflare/stream-react'

export type StreamPlayerEventListener = (event?: Event) => void

export type UseEventResult = {
    // bind event manually
    bindEvent: (
        api: StreamPlayerApi,
        eventCallback: StreamPlayerEventListener
    ) => void
    unbindEvent: (api: StreamPlayerApi) => void
}

/// bind to iframe player event. supports just one callback per event
function useEvent(
    event: string,
    streamApi: MutableRefObject<StreamPlayerApi | null>,
    callback: StreamPlayerEventListener
): UseEventResult {
    const callbackRef = useRef<StreamPlayerEventListener>(null)

    const unbindEvent = useCallback(
        (api: StreamPlayerApi) => {
            if (!api || !callbackRef.current) {
                return
            }

            api.removeEventListener(event, callbackRef.current)
            callbackRef.current = null
        },
        [event]
    )

    const bindEvent = useCallback(
        (api: StreamPlayerApi, eventCallback: StreamPlayerEventListener) => {
            unbindEvent(api)

            if (eventCallback) {
                api.addEventListener(event, eventCallback)
                callbackRef.current = eventCallback
            }
        },
        [event, unbindEvent]
    )

    useEffect(() => {
        const api = streamApi.current

        if (!api) return

        unbindEvent(api)

        if (callback) {
            api.addEventListener(event, callback)
            callbackRef.current = callback
        }

        return () => {
            if (callback) {
                api.removeEventListener(event, callback)
                callbackRef.current = null
            }
        }
    }, [callback, event, streamApi, unbindEvent])

    return useMemo<UseEventResult>(
        () => ({ bindEvent, unbindEvent }),
        [bindEvent, unbindEvent]
    )
}

export default useEvent
