import { FC } from 'react'
import { useIntl, defineMessage } from 'react-intl'
import {
    Stepper,
    LinearProgress,
    Box,
    SxProps,
    Theme,
    Step
} from '@mui/material'

type LeafStepperProps = {
    /** The total number of steps, 1-indexed */
    totalSteps: number
    /** The current step, 1-indexed */
    currentStep: number
    sx?: SxProps<Theme>
}

const STEP_ARIA_LABEL = defineMessage({
    defaultMessage: 'step {stepNumber, number} of {totalSteps, number}',
    description: 'Label for the current step in the process'
})

const LeafStepper: FC<LeafStepperProps> = ({ totalSteps, currentStep, sx }) => {
    const intl = useIntl()
    return (
        <Stepper
            sx={{
                columnGap: 2,
                // to disable the transition animation of the progress bar
                '& .MuiLinearProgress-bar': {
                    transition: 'none'
                },
                ...sx
            }}
            activeStep={currentStep}
            aria-label={intl.formatMessage(STEP_ARIA_LABEL, {
                stepNumber: currentStep,
                totalSteps
            })}
            role="progressbar"
        >
            {Array.from({ length: totalSteps }, (_, index) => {
                const isStepCompleted = currentStep > index
                return (
                    <Box key={`step-${index}`} sx={{ width: '100%' }}>
                        <LinearProgress
                            variant="determinate"
                            value={isStepCompleted ? 100 : 0}
                        />
                    </Box>
                )
            })}
        </Stepper>
    )
}

export default LeafStepper
