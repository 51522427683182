import { LeafTextField } from '../../index'
import { LeafTextFieldProps } from '../../TextField/LeafTextField'

export interface LeafDatePickerProps extends LeafTextFieldProps {
    type?: 'date' | 'datetime-local' | 'time' | 'month' | 'week' | undefined
}

export const LeafDatePicker: React.FC<LeafDatePickerProps> = ({
    type = 'date',
    ...props
}) => {
    return <LeafTextField variant={'outlined'} type={type} {...props} />
}
