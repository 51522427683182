import React, { ReactElement } from 'react'
import { Avatar, AvatarProps, Icon, SvgIcon } from '@mui/material'
import { useTheme } from '../../../../hooks'

export interface LeafBackgroundIconProps extends AvatarProps {
    color?:
        | 'primary'
        | 'secondary'
        | 'accent'
        | 'success'
        | 'error'
        | 'warning'
        | 'info'
    icon: React.ReactChild
}

export const LeafBackgroundIcon: React.FC<LeafBackgroundIconProps> = ({
    color,
    icon,
    ...props
}) => {
    const { sx, ...avatarProps } = props
    const theme = useTheme()
    const handleColorScheme = (color) => {
        switch (color) {
            case 'primary':
                return {
                    bgcolor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText
                }
            case 'secondary':
                return {
                    bgcolor: theme.palette.secondary.light,
                    color: theme.palette.secondary.contrastText
                }
            case 'accent':
                return {
                    bgcolor: theme.palette.accent.light,
                    color: theme.palette.accent.main
                }
            case 'success':
                return {
                    bgcolor: theme.palette.success.light,
                    color: theme.palette.success.dark
                }
            case 'error':
                return {
                    bgcolor: theme.palette.error.light,
                    color: theme.palette.error.main
                }
            case 'warning':
                return {
                    bgcolor: theme.palette.warning.light,
                    color: theme.palette.warning.dark
                }
            case 'info':
                return {
                    bgcolor: theme.palette.info.light,
                    color: theme.palette.info.contrastText
                }
            default:
                return {
                    bgcolor: theme.palette.common.black,
                    color: theme.palette.common.white
                }
        }
    }
    return (
        <Avatar
            {...avatarProps}
            sx={{
                ...sx,
                width: 44,
                height: 44,
                bgcolor: handleColorScheme(color).bgcolor,
                color: handleColorScheme(color).color
            }}
        >
            {(icon as ReactElement).props.icon ? (
                icon
            ) : (
                <Icon fontSize="large">{icon}</Icon>
            )}
        </Avatar>
    )
}

export default LeafBackgroundIcon
