import { defineMessage, MessageDescriptor } from 'react-intl'

const MS_PER_DAY = 86400000

export interface LeafDateRangeProps {
    startDate: Date
    endDate: Date
}

export interface LeafDateRangeTogglePresetProps {
    displayName: MessageDescriptor
    dateRangeCalculator: (currentDate: number) => LeafDateRangeProps
    isCustom?: boolean
}

export const leafDateRangePickerPresets: LeafDateRangeTogglePresetProps[] = [
    {
        displayName: defineMessage({
            defaultMessage: 'Today',
            description: 'Label for a date range option for the current day'
        }),
        dateRangeCalculator: (currentDate) => {
            const start = new Date(
                new Date(new Date(currentDate).setHours(0)).setMinutes(0)
            )
            const end = new Date(
                new Date(
                    new Date(currentDate + MS_PER_DAY).setHours(0)
                ).setMinutes(0)
            )
            return {
                startDate: start,
                endDate: end
            }
        }
    },
    {
        displayName: defineMessage({
            defaultMessage: 'Yesterday',
            description: 'Label for a date range option for the previous day'
        }),
        dateRangeCalculator: (currentDate) => {
            const start = new Date(
                new Date(
                    new Date(currentDate - MS_PER_DAY).setHours(0)
                ).setMinutes(0)
            )
            const end = new Date(
                new Date(new Date(currentDate).setHours(0)).setMinutes(0)
            )
            return {
                startDate: start,
                endDate: end
            }
        }
    },
    {
        displayName: defineMessage({
            defaultMessage: 'Last 7 days',
            description: 'Label for a date range option for the last 7 days'
        }),
        dateRangeCalculator: (currentDate) => {
            const start = new Date(
                new Date(
                    new Date(currentDate - 6 * MS_PER_DAY).setHours(0)
                ).setMinutes(0)
            )
            const end = new Date(
                new Date(new Date(currentDate).setHours(0)).setMinutes(0)
            )
            return {
                startDate: start,
                endDate: end
            }
        }
    },
    {
        displayName: defineMessage({
            defaultMessage: 'Last 30 days',
            description: 'Label for a date range option for the last 30 days'
        }),
        dateRangeCalculator: (currentDate) => {
            const start = new Date(
                new Date(
                    new Date(currentDate - 29 * MS_PER_DAY).setHours(0)
                ).setMinutes(0)
            )
            const end = new Date(
                new Date(new Date(currentDate).setHours(0)).setMinutes(0)
            )
            return {
                startDate: start,
                endDate: end
            }
        }
    }
]
