import React from 'react'
import { SvgIcon } from '@mui/material'
import { useTheme } from '../../../hooks'

const ThriveLogoSingleLeaf = () => {
    const theme = useTheme()

    return (
        <SvgIcon
            viewBox="0 0 560 640"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
                height: '100%',
                width: 'auto',
                minWidth: '30px',
                margin: '0',
                py: theme.spacing(2),
                [theme.breakpoints.down('md')]: {
                    maxHeight: 'calc(var(--navbar-row-height-small) - .8rem)',
                    py: 0
                }
            }}
        >
            <image
                width={'100%'}
                xlinkHref={`${process.env.CDN_ASSETS}/company_logos/Thrive-Mark-Multi.png`}
            />
        </SvgIcon>
    )
}

export default ThriveLogoSingleLeaf
