import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const FullScreenLoading: React.FC = () => (
    <Box
        sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'background.default',
            zIndex: 99998
        }}
        data-testid="fullScreenLoading"
    >
        <CircularProgress />
    </Box>
)

export default FullScreenLoading
