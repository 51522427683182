import {
    Box,
    Button,
    ButtonProps,
    Icon,
    Stack,
    useMediaQuery
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CoreTypography, MarkLogoMulti } from '../../elements'
import { defineMessages, useIntl } from 'react-intl'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { useTheme } from '../../../hooks'
import { ErrorScreenVariant } from '../ErrorScreen'

const messages = defineMessages({
    defaultErrorDescription: {
        defaultMessage: 'Page not found',
        description: 'Page not found'
    },
    defaultUserMessage: {
        defaultMessage: 'Looks like we can’t find the page you’re looking for.',
        description:
            "Explanation to user that the page they're looking for cannot be found."
    },
    serverErrorDescription: {
        defaultMessage: 'Internal server error',
        description: 'Title of server error page'
    },
    serverErrorMessage: {
        defaultMessage:
            'We are experiencing some difficulty loading this page.',
        description: 'Additional details describing the server error'
    },
    authErrorDescription: {
        defaultMessage: 'Authorization required',
        description: 'Title of authorization error page'
    },
    authErrorMessage: {
        defaultMessage:
            'This page is not available for your account. Please contact an administrator for more information.',
        description: 'Additional details describing the authorization error'
    },
    mainPageButtonLabel: {
        defaultMessage: 'Go to main page',
        description:
            'Button text instructing user to go to the main page of the app.'
    }
})

export type LeafErrorCodeProps = {
    variant?: ErrorScreenVariant
    navigationButtonProps?: ButtonProps
    customErrorCode?: number
    customErrorDescription?: string
    customUserMessage?: string
}

export const ErrorCode: React.FC<Partial<LeafErrorCodeProps>> = ({
    variant = ErrorScreenVariant.General,
    navigationButtonProps = {},
    customErrorCode,
    customErrorDescription,
    customUserMessage
}) => {
    const intl = useIntl()
    const navigate = useCrossAppNavigation()
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const [errorCode, setErrorCode] = useState<number>(customErrorCode)
    const [errorDescription, setErrorDescription] = useState<string>(
        customErrorDescription
    )
    const [userMessage, setUserMessage] = useState<string>(customUserMessage)

    const onButtonClicked = () => {
        navigate('/')
    }

    useEffect(() => {
        switch (variant) {
            case ErrorScreenVariant.ServerError:
                setErrorCode(customErrorCode || 500)
                setErrorDescription(
                    customErrorDescription ||
                        intl.formatMessage(messages.serverErrorDescription)
                )
                setUserMessage(
                    customUserMessage ||
                        intl.formatMessage(messages.serverErrorMessage)
                )
                break
            case ErrorScreenVariant.AuthError:
                setErrorCode(customErrorCode || 401)
                setErrorDescription(
                    customErrorDescription ||
                        intl.formatMessage(messages.authErrorDescription)
                )
                setUserMessage(
                    customUserMessage ||
                        intl.formatMessage(messages.authErrorMessage)
                )
                break
            default:
                setErrorCode(customErrorCode || 404)
                setErrorDescription(
                    customErrorDescription ||
                        intl.formatMessage(messages.defaultErrorDescription)
                )
                setUserMessage(
                    customUserMessage ||
                        intl.formatMessage(messages.defaultUserMessage)
                )
        }
    }, [variant, customErrorCode, customErrorDescription, customUserMessage])

    return (
        <Stack direction={smDown ? 'column' : 'row'} spacing={1}>
            <Stack spacing={1} width={'100%'}>
                <CoreTypography variant="overline">{errorCode}</CoreTypography>
                <CoreTypography variant="h1">
                    {errorDescription ||
                        intl.formatMessage(messages.defaultErrorDescription)}
                </CoreTypography>

                <CoreTypography variant="body1">
                    {userMessage ||
                        intl.formatMessage(messages.defaultUserMessage)}
                </CoreTypography>
            </Stack>
            <Box component="nav" alignSelf={smDown ? 'start' : 'center'}>
                <Button
                    component="nav"
                    onClick={onButtonClicked}
                    endIcon={
                        <Icon sx={{ display: 'flex' }}>
                            <MarkLogoMulti sx={{ display: 'flex' }} />
                        </Icon>
                    }
                    {...navigationButtonProps}
                    sx={{
                        ...navigationButtonProps.sx,
                        whiteSpace: 'nowrap'
                    }}
                >
                    <CoreTypography variant="body1">
                        {intl.formatMessage(messages.mainPageButtonLabel)}
                    </CoreTypography>
                </Button>
            </Box>
        </Stack>
    )
}

export default ErrorCode
