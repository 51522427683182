import React, { ReactElement, PropsWithChildren } from 'react'
import { Grid, Toolbar, Typography } from '@mui/material'

export interface TabHeaderProps {
    title: string | ReactElement
    subtitle?: string | ReactElement
    hideTabs?: boolean //tabs shown by default
    showSearch?: boolean //search hidden by default
    search?: ReactElement
}

const TabHeader: React.FC<PropsWithChildren<TabHeaderProps>> = ({
    title,
    subtitle,
    hideTabs = false,
    showSearch = false,
    search,
    ...props
}) => {
    return (
        <>
            <Toolbar
                container
                component={Grid}
                columns={12}
                sx={{
                    pt: 4,
                    px: '0 !important',
                    color: 'primary.main'
                }}
            >
                <Grid item xs={12}>
                    <Typography variant="h1" data-testid="tab-header-title">
                        {title}
                    </Typography>
                </Grid>
                {subtitle && (
                    <Grid item xs={12} sx={{ my: 1 }}>
                        <Typography
                            variant="body1"
                            data-testid="tab-header-subtitle"
                        >
                            {subtitle}
                        </Typography>
                    </Grid>
                )}
                {!hideTabs && (
                    <Grid
                        item
                        xs={12}
                        data-testid="tab-header-static-tab-group"
                    >
                        {props.children}
                    </Grid>
                )}
            </Toolbar>
        </>
    )
}

export default TabHeader
