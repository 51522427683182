import { CustomisedTransition } from './type'

export const baseTransition: CustomisedTransition = {
    duration: 2.5,
    repeat: Infinity,
    repeatDelay: 0.5
}

export const getDelayedTransition = (
    customisedTransition: CustomisedTransition,
    delayedBy: number
) => ({
    duration: customisedTransition.duration * delayedBy,
    repeatDelay:
        customisedTransition.repeatDelay +
        (customisedTransition.duration -
            customisedTransition.duration * delayedBy)
})
