import {
    imageConfig,
    maxImageWidth
} from '../../components/elements/Images/utils'
import useWindowWidth from '../useWindowWidth'
import { useMemo } from 'react'

type UseImageSizingProps = {
    src: string
    maxWidth?: number | undefined
}

const useImageSizing = ({ src, maxWidth }: UseImageSizingProps) => {
    const windowWidth = useWindowWidth()

    const finalWidth = useMemo(() => {
        return maxWidth !== undefined
            ? maxImageWidth(maxWidth)
            : maxImageWidth(windowWidth)
    }, [maxWidth, windowWidth])

    const srcMain = useMemo(() => {
        return src.replace('/cms', `/width=${finalWidth}`)
    }, [src, finalWidth])

    const srcSet = useMemo(() => {
        const set = []
        imageConfig.srcSetWidths.forEach((w) => {
            if (!maxWidth || w <= maxWidth) {
                set.push(src.replace('cms', `width=${w}`).concat(` ${w}w`))
            }
        })
        return set
    }, [src, maxWidth])

    return {
        finalWidth,
        srcMain,
        srcSet
    }
}

export default useImageSizing
