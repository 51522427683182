import { Icon, InputAdornment, ListSubheader, MenuItem } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import {
    CoreTypography,
    LeafSelect,
    LeafTextField,
    IconButton
} from '../../index'
import { defineMessages, useIntl } from 'react-intl'
import { useTheme } from '../../../../hooks'
import { LeafSelectProps } from '../../Select/LeafSelect'
import LeafIcon from '../../../foundations/icons'

export interface LeafSelectAutocompleteOption {
    label: string
    value: any
}

export type LeafSelectAutocompleteProps = LeafSelectProps & {
    options: LeafSelectAutocompleteOption[]
    renderOption?: (option: LeafSelectAutocompleteOption) => ReactNode
}

const messages = defineMessages({
    searchLabel: {
        defaultMessage: 'Search',
        description: 'Label for search bar'
    },
    noResults: {
        defaultMessage: 'No results',
        description: 'Message to user that there are no search results'
    },
    clearSearch: {
        defaultMessage: 'Clear search text',
        description: 'Label for button that clears search text'
    }
})

const LeafSelectAutocomplete: React.FC<LeafSelectAutocompleteProps> = ({
    options,
    renderOption,
    onClose = (e) => {},
    ...props
}) => {
    const intl = useIntl()
    const theme = useTheme()

    const [filteredOptions, setFilteredOptions] =
        useState<LeafSelectAutocompleteOption[]>(options)
    const [noResults, setNoResults] = useState<boolean>(options.length === 0)
    const [searchValue, setSearchValue] = useState<string>('')

    const onSearchKeydown = (e) => {
        if (e.key !== 'Escape') {
            //this is necessary to prevent the Menu from closing on tab/arrow key and prevent auto-focus behavior
            e.stopPropagation()
        }
    }

    const onSearchChange = (e) => {
        setSearchValue(e.target.value)
        const newFilteredOptions = options.filter((o) =>
            o.label.toLowerCase().includes(e.target.value.toLowerCase())
        )
        setFilteredOptions(newFilteredOptions)
        setNoResults(newFilteredOptions.length === 0)
    }

    const clearSearch = () => {
        setSearchValue('')
        setFilteredOptions(options)
        setNoResults(options.length === 0)
    }

    const onSelectClose = (e) => {
        onClose(e)
        clearSearch()
    }

    return (
        <LeafSelect
            data-testid={'leaf-select-component'}
            {...props}
            onClose={onSelectClose}
            MenuProps={{
                ...props.MenuProps,
                transitionDuration: 150,
                variant: 'menu'
            }}
        >
            <ListSubheader sx={{ padding: theme.spacing(1, 2), mt: -1 }}>
                <LeafTextField
                    variant={'outlined'}
                    role={'search'}
                    id={'select-search-field'}
                    data-testid={'select-search-field'}
                    label={intl.formatMessage(messages.searchLabel)}
                    onChange={onSearchChange}
                    onKeyDown={onSearchKeydown}
                    value={searchValue}
                    inputAdornment={
                        <InputAdornment position={'end'}>
                            <IconButton
                                onClick={clearSearch}
                                ariaLabel={intl.formatMessage(
                                    messages.clearSearch
                                )}
                            >
                                <LeafIcon icon={'xmark'} fontSize={'small'} />
                            </IconButton>
                        </InputAdornment>
                    }
                    formControlProps={{ fullWidth: true }}
                />
                {noResults && (
                    <ListSubheader sx={{ pb: 2, textAlign: 'center' }}>
                        <CoreTypography variant={'body1'}>
                            {intl.formatMessage(messages.noResults)}
                        </CoreTypography>
                    </ListSubheader>
                )}
            </ListSubheader>
            {filteredOptions.map((m, i) => (
                <MenuItem
                    key={i}
                    value={m.value}
                    data-testid={'select-autocomplete-option'}
                >
                    {renderOption ? renderOption(m) : m.label}
                </MenuItem>
            ))}
        </LeafSelect>
    )
}

export default LeafSelectAutocomplete
