import { Box, Button, ButtonProps, CircularProgress, Fade } from '@mui/material'
import React from 'react'

export interface LoadingButtonProps extends ButtonProps {
    loading: boolean
    loader?: React.ReactNode

    //fixes width of button to width of child element/text. Should only be set true when loader is smaller than button text
    fixWidth: boolean
}

// NOTE: button is not disabled by default when loading, that is up to individual implementation

export const LoadingButton = React.forwardRef<
    HTMLButtonElement,
    LoadingButtonProps
>((props, ref) => {
    const { loading, loader, fixWidth, ...other } = props

    return (
        <Button {...other} ref={ref}>
            {loading && (
                <Fade in={loading}>
                    <Box
                        sx={{ position: fixWidth ? 'absolute' : 'relative' }}
                        data-testid="loading-button-loader"
                    >
                        {loader || (
                            <CircularProgress
                                size={24}
                                sx={{
                                    verticalAlign: 'middle',
                                    color: 'inherit'
                                }}
                            />
                        )}
                    </Box>
                </Fade>
            )}
            {(fixWidth || !loading) && (
                <Box
                    sx={loading ? { visibility: 'hidden' } : {}}
                    data-testid="loading-button-content"
                >
                    {props.children}
                </Box>
            )}
        </Button>
    )
})

export default LoadingButton
