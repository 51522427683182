import { SvgIcon } from '@mui/material'

const Achievement = ({ ...props }) => (
    <SvgIcon
        width="40"
        height="44"
        viewBox="0 0 40 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity="0.1">
            <path
                d="M0 16.4022C0 12.9131 1.90172 9.70134 4.96104 8.0237L14.9984 2.5195C17.8604 0.950076 21.3255 0.950076 24.1874 2.5195L34.2248 8.0237C37.2841 9.70134 39.1858 12.9131 39.1858 16.4022V27.9777C39.1858 31.5364 37.2082 34.8003 34.0538 36.4477L24.0164 41.6898C21.245 43.1372 17.9408 43.1372 15.1694 41.6898L5.13203 36.4477C1.9776 34.8003 0 31.5364 0 27.9777V16.4022Z"
                fill="#4D1AAD"
            />
            <path
                d="M0 16.4022C0 12.9131 1.90172 9.70134 4.96104 8.0237L14.9984 2.5195C17.8604 0.950076 21.3255 0.950076 24.1874 2.5195L34.2248 8.0237C37.2841 9.70134 39.1858 12.9131 39.1858 16.4022V27.9777C39.1858 31.5364 37.2082 34.8003 34.0538 36.4477L24.0164 41.6898C21.245 43.1372 17.9408 43.1372 15.1694 41.6898L5.13203 36.4477C1.9776 34.8003 0 31.5364 0 27.9777V16.4022Z"
                fill="url(#paint0_radial_1123_59013)"
                fillOpacity="0.15"
            />
        </g>
        <path
            opacity="0.1"
            d="M1.83691 17.1422C1.83691 13.8724 3.62185 10.8635 6.49136 9.29594L15.3068 4.48032C17.978 3.02114 21.208 3.02115 23.8791 4.48032L32.6946 9.29594C35.5641 10.8635 37.3491 13.8724 37.3491 17.1422V27.1966C37.3491 30.5314 35.4932 33.589 32.5348 35.128L23.7194 39.7144C21.133 41.0599 18.053 41.0599 15.4666 39.7144L6.65115 35.128C3.69282 33.589 1.83691 30.5314 1.83691 27.1966V17.1422Z"
            stroke="#16003F"
            strokeWidth="1.23747"
        />
        <path
            d="M25.625 15.375H23.875V13.625H15.125V15.375H13.375C12.4125 15.375 11.625 16.1625 11.625 17.125V18C11.625 20.2312 13.305 22.0512 15.4662 22.3225C16.0175 23.635 17.1988 24.6237 18.625 24.9125V27.625H15.125V29.375H23.875V27.625H20.375V24.9125C21.8013 24.6237 22.9825 23.635 23.5338 22.3225C25.695 22.0512 27.375 20.2312 27.375 18V17.125C27.375 16.1625 26.5875 15.375 25.625 15.375ZM15.125 20.4675C14.11 20.1 13.375 19.1375 13.375 18V17.125H15.125V20.4675ZM25.625 18C25.625 19.1375 24.89 20.1 23.875 20.4675V17.125H25.625V18Z"
            fill="#1E0056"
        />
        <defs>
            <radialGradient
                id="paint0_radial_1123_59013"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(19.8378 23.1687) rotate(119.24) scale(19.3034 18.2611)"
            >
                <stop offset="0.510417" stopColor="#9747FF" />
                <stop offset="1" stopColor="#250B47" />
            </radialGradient>
        </defs>
    </SvgIcon>
)

export default Achievement
