import { useMemo } from 'react'
import { StreamProps } from '@cloudflare/stream-react'

export type UseIframePlayerSrcOptions = {
    muted?: boolean
    loop?: boolean
    autoplay?: boolean
    controls?: boolean
    poster?: string
    primaryColor?: string
    letterboxColor?: string
    startTime?: string | number
    preload?: StreamProps['preload']
    defaultTextTrack?: string
}

function useIframePlayerSrc(
    src: string,
    {
        muted,
        preload,
        loop,
        autoplay,
        controls,
        poster,
        primaryColor,
        letterboxColor,
        startTime,
        defaultTextTrack
    }: UseIframePlayerSrcOptions
): string {
    const iframeSrc = useMemo(() => {
        if (!src) return src

        const srcUrl = new URL(src)
        if (primaryColor) {
            srcUrl.searchParams.set(
                'primaryColor',
                encodeURIComponent(primaryColor)
            )
        }

        if (letterboxColor) {
            srcUrl.searchParams.set(
                'letterboxColor',
                encodeURIComponent(letterboxColor)
            )
        }

        if (startTime) {
            srcUrl.searchParams.set('startTime', startTime.toString())
        }

        if (muted) {
            srcUrl.searchParams.set('muted', 'true')
        }

        if (preload) {
            srcUrl.searchParams.set(
                'preload',
                preload === true ? 'auto' : preload
            )
        }

        if (loop) {
            srcUrl.searchParams.set('loop', 'true')
        }

        if (autoplay) {
            srcUrl.searchParams.set('autoplay', 'true')
        }

        if (controls === false) {
            srcUrl.searchParams.set('controls', 'false')
        }

        if (poster) {
            srcUrl.searchParams.set('poster', poster)
        }

        if (defaultTextTrack) {
            srcUrl.searchParams.set('defaultTextTrack', defaultTextTrack)
        }

        return srcUrl.toString()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]) // only update when src changes, other props are static

    return iframeSrc
}

export default useIframePlayerSrc
