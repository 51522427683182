import { FC } from 'react'
import { motion } from 'framer-motion'

import { LeafLogoSVGProps } from './type'
import { getDelayedTransition } from './config'

const VeinsSVG: FC<LeafLogoSVGProps> = ({
    baseTransition,
    stopOnCompletion
}) => (
    <motion.g
        initial={{ opacity: 1 }}
        animate={{
            opacity: stopOnCompletion ? 1 : [1, 1, 1, 0],
            transition: { ...baseTransition, ease: 'easeInOut' }
        }}
    >
        <motion.rect
            id="center-vein"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(300.500000, 374.000000) rotate(-180.000000) translate(-300.500000, -374.000000) "
            x="292"
            y="45"
            rx={12}
            width="30"
            height="685"
            initial={{ height: 0 }}
            animate={{
                height: 685,
                transition: {
                    ...baseTransition,
                    ...getDelayedTransition(baseTransition, 0.6),
                    ease: 'easeOut'
                }
            }}
        ></motion.rect>
        <motion.rect
            id="bottom-left-vein"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(157.921442, 361.386002) scale(-1, 1) rotate(-135.000000) translate(-157.921442, -361.386002) "
            x="149.921442"
            y="157.386002"
            rx={12}
            width="30"
            height="390"
            initial={{ height: 0, opacity: 0 }}
            animate={{
                height: 390,
                opacity: [0, 1, 1],
                transition: {
                    ...baseTransition,
                    ...getDelayedTransition(baseTransition, 0.5),
                    ease: 'easeInOut'
                }
            }}
        ></motion.rect>
        <motion.rect
            id="top-left-vein"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(207.675909, 175.019380) scale(-1, 1) rotate(225.000000) translate(-207.675909, -175.019380) "
            x="200.175909"
            y="37.0193801"
            rx={12}
            width="30"
            height="260"
            initial={{ height: 0, opacity: 0 }}
            animate={{
                height: 260,
                opacity: [0, 1, 1],
                transition: {
                    ...baseTransition,
                    ...getDelayedTransition(baseTransition, 0.6),
                    ease: 'easeInOut'
                }
            }}
        ></motion.rect>
        <motion.rect
            id="top-right-vein"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(394.524093, 175.354067) rotate(-135.000000) translate(-394.524093, -175.354067) "
            x="386.524093"
            y="35.3540667"
            rx={12}
            width="30"
            height="265"
            initial={{ height: 0, opacity: 0 }}
            animate={{
                height: 265,
                opacity: [0, 1, 1],
                transition: {
                    ...baseTransition,
                    ...getDelayedTransition(baseTransition, 0.7),
                    ease: 'easeInOut'
                }
            }}
        ></motion.rect>
        <motion.rect
            id="bottom-right-vein"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(443.382219, 363.618170) rotate(225.000000) translate(-443.382219, -363.618170) "
            x="435.882219"
            y="154.61817"
            rx={12}
            width="30"
            height="400"
            initial={{ height: 0, opacity: 0 }}
            animate={{
                height: 400,
                opacity: [0, 1, 1],
                transition: {
                    ...baseTransition,
                    ...getDelayedTransition(baseTransition, 0.8),
                    ease: 'easeInOut'
                }
            }}
        ></motion.rect>
    </motion.g>
)

export default VeinsSVG
