/* eslint-disable @thriveglobal/sentence-case/sentence-case */
import { FC, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { defineMessages, useIntl } from 'react-intl'

import {
    useBreadcrumbs,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'

import LeafLogoAnimation from '../../Animations/LeafLogoAnimation'
import { CoreTypography } from '../../CoreTypography'
import { useTheme } from '../../../../hooks'
import { LeafLogoAnimationProps } from '../../Animations/LeafLogoAnimation/type'

interface BackgroundProps {
    backgroundgradient: string
}

const Background = styled('div')<BackgroundProps>(({ backgroundgradient }) => ({
    top: 'calc(0px - var(--navbar-height))',
    left: '0',
    position: 'absolute',
    height: 'calc(100% + var(--navbar-height))',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: backgroundgradient,
    zIndex: 1099 // so it does not obscure the nav bar (see https://mui.com/material-ui/customization/z-index/)
}))

const messages = defineMessages({
    markTwainQuote: {
        defaultMessage: 'The secret of getting ahead is getting started.',
        description: 'Mark Twain quote'
    },
    markTwainName: {
        defaultMessage: 'Mark Twain',
        description: "Mark Twain's name"
    }
})

const ThriveFullscreenLoading: FC<LeafLogoAnimationProps> = ({
    duration,
    svgStyle,
    stopOnCompletion
}) => {
    const intl = useIntl()
    const theme = useTheme()

    const isThriveFullScreenLoaderDisabled = useIsFeatureEnabled(
        'isThriveFullScreenLoaderDisabled',
        false,
        true
    )

    useBreadcrumbs([])
    if (isThriveFullScreenLoaderDisabled) {
        return null
    }

    return (
        <Background backgroundgradient={theme.gradient.main}>
            <LeafLogoAnimation
                duration={duration}
                svgStyle={svgStyle}
                stopOnCompletion={stopOnCompletion}
            />
            <CoreTypography
                customVariant="display4"
                pt={4}
                textAlign="center"
                px={6}
            >
                {intl.formatMessage(messages.markTwainQuote)}
            </CoreTypography>
            <CoreTypography variant="h6" pt={1}>
                {intl.formatMessage(messages.markTwainName).toLocaleUpperCase()}
            </CoreTypography>
        </Background>
    )
}

export { ThriveFullscreenLoading, LeafLogoAnimation as ThriveLogoLoading }
