import { styled } from '@mui/material/styles'
import React from 'react'

export function getTruncateStyles() {
    return {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    } as const
}

export function getLineClampStyles(lines: number) {
    return {
        display: '-webkit-box',
        WebkitLineClamp: `${lines}`,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    } as const
}

export type TruncatedProps = { textLines?: number }

export const withTruncatedText = function <T>(
    Component: React.ComponentType<T>
) {
    return styled(Component, {
        shouldForwardProp: (prop) => {
            return prop !== 'textLines'
        }
    })((props: T & TruncatedProps): {} => {
        const lines = props.textLines ?? 1

        const styles =
            lines === 1
                ? getTruncateStyles()
                : getLineClampStyles(props.textLines)

        return { ...styles }
    }) as React.FC<T & TruncatedProps>
}
