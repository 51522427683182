import { SvgIcon, SvgIconProps } from '@mui/material'

const Challenges = (props: SvgIconProps) => (
    <SvgIcon
        aria-hidden="true"
        viewBox="0 0 12 14"
        fill="none"
        data-testid="Challenges"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 1.92173V6.99446C1.24466 6.88147 1.54009 6.76921 1.87873 6.68454C2.92664 6.42257 4.36451 6.43269 5.94279 7.4473C7.26106 8.29476 8.4094 8.28464 9.22356 8.0811C9.63698 7.97774 9.97033 7.82249 10.1988 7.69396C10.254 7.66292 10.3028 7.6336 10.3448 7.60716V2.53443C10.1002 2.64742 9.80474 2.75968 9.4661 2.84434C8.41819 3.10632 6.98032 3.0962 5.40204 2.08158C4.08377 1.23413 2.93543 1.24425 2.12127 1.44779C1.70785 1.55114 1.3745 1.70639 1.14599 1.83493C1.09082 1.86597 1.04203 1.89529 1 1.92173ZM10.5286 1.27369L10.5163 1.28313C10.5041 1.29243 10.4837 1.30751 10.4556 1.32707C10.3994 1.36623 10.3127 1.423 10.1988 1.48706C9.97033 1.6156 9.63698 1.77085 9.22356 1.8742C8.4094 2.07774 7.26106 2.08786 5.94279 1.24041C4.36451 0.225794 2.92664 0.215672 1.87873 0.477648C1.36111 0.607053 0.944467 0.80094 0.655732 0.963354C0.511003 1.04476 0.397242 1.11892 0.317701 1.17432C0.277894 1.20204 0.246541 1.22515 0.224048 1.24221C0.212797 1.25075 0.203751 1.25778 0.19696 1.26314L0.188465 1.2699L0.185495 1.2723L0.184331 1.27324C0.184102 1.27343 0.183381 1.27402 0.5 1.661L0.183381 1.27402C0.0673134 1.36898 0 1.51103 0 1.661V12.8334C0 13.1096 0.223858 13.3334 0.5 13.3334C0.776142 13.3334 1 13.1096 1 12.8334V8.12862C1.04203 8.10218 1.09082 8.07286 1.14599 8.04183C1.3745 7.91329 1.70785 7.75804 2.12127 7.65469C2.93543 7.45115 4.08377 7.44102 5.40204 8.28848C6.98032 9.30309 8.41819 9.31321 9.4661 9.05124C9.98372 8.92183 10.4004 8.72795 10.6891 8.56553C10.8338 8.48412 10.9476 8.40996 11.0271 8.35457C11.0669 8.32685 11.0983 8.30374 11.1208 8.28668C11.132 8.27814 11.1411 8.2711 11.1479 8.26575L11.1564 8.25899L11.1593 8.25659L11.1605 8.25565C11.1607 8.25546 11.1614 8.25487 10.8448 7.86789L11.1614 8.25487C11.2775 8.15991 11.3448 8.01786 11.3448 7.86789V1.661C11.3448 1.4678 11.2335 1.29189 11.0589 1.20916C10.8845 1.1265 10.6781 1.15163 10.5286 1.27369Z"
            fill="currentcolor"
        />
    </SvgIcon>
)

export default Challenges
