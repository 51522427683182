import { IframePlayerProps } from './IframePlayer'

const companyId = 'repfrq2uozf9ok0e'

// Temporary workaround to get the manifest URL
export const getIframeVideoManifestSrc = (iframeVideoSrc: string): string => {
    if (!iframeVideoSrc) return iframeVideoSrc

    const iframeUrl = new URL(iframeVideoSrc)

    const videoId = iframeUrl.pathname.split('/').pop()

    const manifestUrl = new URL(
        `${videoId}/manifest/video.m3u8`,
        `https://customer-${companyId}.cloudflarestream.com`
    )

    return manifestUrl.toString()
}

export const parseStartTime = (startTime: string): number => {
    if (!startTime) return 0

    // Parse "1h12m27s"
    const timeRegex = /(\d+)h(\d+)m(\d+)s/
    const match = startTime.match(timeRegex)

    if (match) {
        const hours = +match[1]
        const minutes = +match[2]
        const seconds = +match[3]

        const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds

        return totalSeconds
    }

    // Parse "123"
    const startTimeValue = +startTime
    if (!isNaN(startTimeValue)) {
        return startTimeValue
    }

    return 0
}

export const parseIframeVideoSrcParams = (
    iframeVideoSrc: string
): Partial<IframePlayerProps> => {
    if (!iframeVideoSrc) return {}

    const iframeUrl = new URL(iframeVideoSrc)
    const searchParams = iframeUrl.searchParams

    const params: Partial<IframePlayerProps> = {}

    if (searchParams.has('muted')) {
        params.muted = searchParams.get('muted').toLowerCase() === 'true'
    }

    if (searchParams.has('preload')) {
        params.preload = searchParams.get('preload') as typeof params.preload
    }

    if (searchParams.has('loop')) {
        params.loop = searchParams.get('loop').toLowerCase() === 'true'
    }

    if (searchParams.has('autoplay')) {
        params.autoplay = searchParams.get('autoplay').toLowerCase() === 'true'
    }

    if (searchParams.has('controls')) {
        params.controls = searchParams.get('controls').toLowerCase() !== 'false'
    }

    if (searchParams.has('poster')) {
        params.poster = searchParams.get('poster')
    }

    if (searchParams.has('primaryColor')) {
        params.primaryColor = decodeURIComponent(
            searchParams.get('primaryColor')
        )
    }

    if (searchParams.has('letterboxColor')) {
        params.letterboxColor = decodeURIComponent(
            searchParams.get('letterboxColor')
        )
    }

    if (searchParams.has('startTime')) {
        params.startTime = parseStartTime(searchParams.get('startTime'))
    }

    if (searchParams.has('defaultTextTrack')) {
        params.defaultTextTrack = searchParams.get('defaultTextTrack')
    }

    return params
}
