import { Box } from '@mui/material'
import { useAnimation } from 'framer-motion'
import React, { useCallback, useEffect } from 'react'
import BurstIcon from '../../../icons/BurstIcon'
import { CoreTypography } from '../../CoreTypography'
import { useTheme } from '../../../../hooks'

export type BurstAnimationProps = {
    skipAnimation: boolean
    softenText: boolean
    completeAction?: () => void
    text: string
    size: number
    color: string
    duration?: number
    children: React.ReactNode
}

const BurstAnimation: React.FC<BurstAnimationProps> = ({
    skipAnimation,
    softenText,
    completeAction,
    text,
    size,
    color,
    duration,
    children
}) => {
    const theme = useTheme()
    const BurstControl = useAnimation()

    const endSequence = useCallback(async () => {
        await BurstControl.start({
            transition: { delay: 0, duration: 0 },
            display: 'none',
            visibility: 'hidden'
        })
        BurstControl.stop()
        await new Promise((resolve) => setTimeout(resolve, duration))
        completeAction()
    }, [BurstControl, completeAction, duration])

    useEffect(() => {
        const burstSequence = async () => {
            await BurstControl.start({
                transition: { delay: 0, duration: 0.1 },
                pathLength: 0.5,
                display: 'block',
                visibility: 'visible'
            })
            await BurstControl.start({
                transition: { delay: 0, duration: 0 },
                rotate: 180
            })
            await BurstControl.start({
                transition: { delay: 0, duration: 0.2 },
                pathLength: 0.2
            })
            await BurstControl.start({
                transition: { delay: 0, duration: 0.1 },
                pathLength: 0.001
            })
            await endSequence()
        }

        if (!skipAnimation) {
            burstSequence()
        }
    }, [skipAnimation, BurstControl, endSequence])

    useEffect(() => {
        if (skipAnimation) {
            endSequence()
        }
    }, [skipAnimation, endSequence])

    return (
        <Box
            onClick={() => completeAction()}
            data-testid="burst-animation"
            sx={{
                maxWidth: 'fit-content'
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    backgroundColor: 'transparent',
                    color: softenText
                        ? theme.palette.grey[600]
                        : theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <BurstIcon
                    key={`burst-animation`}
                    animate={BurstControl}
                    minHeight={theme.spacing(size)}
                    color={color ? color : theme.palette.primary.main}
                />
                {text && (
                    <CoreTypography
                        variant="h5"
                        sx={{
                            width: 'fit-content',
                            py: theme.spacing(1),
                            px: theme.spacing(2.5),
                            whiteSpace: 'nowrap'
                        }}
                        data-testid="animation-text"
                    >
                        {text}
                    </CoreTypography>
                )}
                <Box>{children}</Box>
            </Box>
        </Box>
    )
}

export default React.memo(BurstAnimation)
