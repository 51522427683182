import React from 'react'
import { getBestFitLocale, Locale } from '@thriveglobal/thrive-web-core'
import LeafExternalLink, { LeafExternalLinkProps } from '../../LeafExternalLink'

export interface BasePolicyLinkProps extends LeafExternalLinkProps {
    linkText: React.ReactNode
    linkMap: { [key in Locale]: string }
}

const LeafBasePolicyLink: React.FC<BasePolicyLinkProps> = ({
    linkText,
    linkMap,
    ...props
}) => {
    const bestFitPolicyLink =
        linkMap[getBestFitLocale()] ?? linkMap[Locale.EN_US]

    return (
        <LeafExternalLink
            display="inline-flex"
            externalLink={bestFitPolicyLink}
            tabIndex={0}
            {...props}
        >
            {linkText}
        </LeafExternalLink>
    )
}

export default LeafBasePolicyLink
