import { useEffect, useRef } from 'react'

export const useSyncStateWithRef = <T>(state: T): React.MutableRefObject<T> => {
    const ref = useRef<T>(state)

    useEffect(() => {
        ref.current = state
    }, [state])

    return ref
}
