import {
    CardProps,
    Card,
    CardMediaProps,
    Box,
    CardActionAreaProps,
    CardActionArea
} from '@mui/material'
import React, { ReactElement } from 'react'

interface LeafBaseMediaCardProps extends CardProps {
    media: ReactElement<CardMediaProps>
    flexMedia?: boolean
    actionAreaProps?: CardActionAreaProps
    alignMedia?: 'top' | 'left' | undefined
    mediaWidth?: string
    mediaHeight?: string
}

const LeafBaseMediaCard: React.FC<LeafBaseMediaCardProps> = ({
    media,
    flexMedia = false,
    actionAreaProps,
    alignMedia = 'left',
    mediaWidth = flexMedia ? 'fit-content' : '50%',
    mediaHeight = '150px',
    ...props
}) => {
    const { children, sx, ...otherCardProps } = props

    const isTopAligned = alignMedia === 'top'
    const isLeftAligned = alignMedia === 'left'

    return (
        <Card
            {...otherCardProps}
            sx={{
                ...sx,
                display: 'flex',
                flexDirection: isTopAligned
                    ? 'column'
                    : isLeftAligned
                    ? 'row'
                    : 'row-reverse', //row reverse here in case we want to add "right" alignment as an option
                // The overrides for height in CardActionArea and CardContent are necessary to explicitly set the height of the card
                '.MuiCardActionArea-root': isTopAligned
                    ? { height: 'inherit' }
                    : {},
                '.MuiCardContent-root': isTopAligned
                    ? { height: `calc(100% - ${mediaHeight})` }
                    : {},
                '.MuiCardMedia-root': isTopAligned
                    ? {
                          minHeight: mediaHeight,
                          marginBottom: 0
                      }
                    : {
                          width: mediaWidth,
                          padding: flexMedia ? 2 : 0,
                          marginRight: 0
                      }
            }}
        >
            {isTopAligned ? (
                actionAreaProps ? (
                    <>
                        <CardActionArea {...actionAreaProps}>
                            {media}
                            {children}
                        </CardActionArea>
                    </>
                ) : (
                    <>
                        {media}
                        {children}
                    </>
                )
            ) : //is Left aligned
            actionAreaProps !== undefined ? (
                <>
                    <CardActionArea
                        {...actionAreaProps}
                        sx={{
                            display: 'flex',
                            flexDirection: isLeftAligned
                                ? 'row'
                                : 'row-reverse', //row reverse here in case we want to add "right" alignment as an option
                            '.MuiCardMedia-root': { height: '100%' }
                        }}
                    >
                        {flexMedia ? (
                            <div style={{ height: '100%' }}>{media}</div>
                        ) : (
                            media
                        )}
                        <Box
                            width={
                                flexMedia
                                    ? '100%'
                                    : `calc(100% - ${mediaWidth})`
                            }
                        >
                            {children}
                        </Box>
                    </CardActionArea>
                </>
            ) : (
                <>
                    {media}
                    <Box
                        flexDirection={'column'}
                        sx={
                            isTopAligned
                                ? {}
                                : {
                                      width: flexMedia
                                          ? '100%'
                                          : `calc(100% - ${mediaWidth})`
                                  }
                        }
                    >
                        {children}
                    </Box>
                </>
            )}
        </Card>
    )
}

export default LeafBaseMediaCard
