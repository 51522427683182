import { styled } from '@mui/material/styles'

export const VisuallyHidden = ({ children }) => {
    // https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
    const VisuallyHiddenDiv = styled('div')`
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    `

    return <VisuallyHiddenDiv>{children}</VisuallyHiddenDiv>
}
