import { IconButton, IconButtonProps } from '@mui/material'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LeafIcon from '../../../foundations/icons'

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'close-reset-player',
        defaultMessage: 'Close player',
        description: 'Label describing button to close player popup'
    }
})

export type CloseResetPlayerButtonProps = IconButtonProps

const iconButtonSx = {
    position: 'absolute',
    fontSize: '2em',
    right: 0,
    mr: '0.5em',
    mt: '0.5em',
    zIndex: 3
}

function CloseResetPlayerButton(props: CloseResetPlayerButtonProps) {
    const { sx, ...restProps } = props
    const { formatMessage } = useIntl()
    return (
        <IconButton
            sx={[iconButtonSx, ...(Array.isArray(sx) ? sx : [sx])]}
            color={'info'}
            aria-label={formatMessage(messages.closeButtonAriaLabel)}
            {...restProps}
        >
            <LeafIcon icon={'xmark'} />
        </IconButton>
    )
}

export default React.memo(CloseResetPlayerButton)
