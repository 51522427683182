import React from 'react'
import { CardMedia, CardMediaProps } from '@mui/material'
import { useTheme } from '../../../../hooks'

const LeafInsetCardMedia: React.FC<CardMediaProps> = ({ ...props }) => {
    const theme = useTheme()
    return (
        <CardMedia
            sx={{ margin: theme.spacing(2), borderRadius: '8px' }}
            {...props}
        />
    )
}

export default LeafInsetCardMedia
