import {
    FormControlProps,
    InputAdornmentProps,
    OutlinedTextFieldProps,
    TextField
} from '@mui/material'
import { HEIGHTS } from '../../../../theme'
import { ReactElement } from 'react'
import { LeafFormControl } from '../../Forms/LeafFormControl'

export interface LeafTextFieldProps extends OutlinedTextFieldProps {
    label: string
    id: string
    helperText?: string
    inputAdornment?: ReactElement<InputAdornmentProps>
    formControlProps?: FormControlProps
}

export const LeafTextField: React.FC<LeafTextFieldProps> = ({
    id,
    label,
    helperText,
    inputAdornment,
    formControlProps,
    color = 'primary',
    ...props
}) => {
    const { InputProps, sx, ...otherProps } = props

    return (
        <LeafFormControl
            label={label}
            id={id}
            helperText={helperText}
            fullWidth={props.fullWidth}
            error={props.error}
            disabled={props.disabled}
            color={color}
            {...formControlProps}
        >
            <TextField
                {...otherProps}
                color={color}
                id={id}
                variant={'outlined'}
                error={formControlProps?.error}
                InputProps={{
                    ...InputProps,
                    endAdornment:
                        inputAdornment?.props.position === 'end'
                            ? inputAdornment
                            : null,
                    startAdornment:
                        inputAdornment?.props.position === 'start'
                            ? inputAdornment
                            : null
                }}
                sx={{
                    ...sx,
                    '.MuiInputBase-root': {
                        borderRadius: '6px !important',
                        height: HEIGHTS.LARGE_COMPONENT_HEIGHT
                    },
                    '.MuiOutlinedInput-input': {
                        '::placeholder': {
                            color: 'text.secondary',
                            opacity: 1
                        }
                    }
                }}
            >
                {props.children}
            </TextField>
        </LeafFormControl>
    )
}

export default LeafTextField
