import useImageSizing from '../../../../hooks/useImageSizing'

interface LeafImageDynamicProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {
    maxWidth?: number | undefined
    src: string
    alt?: string
    forceLoad?: boolean
}

export const LeafImageDynamicLazy = ({
    forceLoad = false,
    maxWidth = undefined,
    src,
    alt,
    ...props
}: LeafImageDynamicProps) => {
    const { srcMain, srcSet } = useImageSizing({ src, maxWidth })

    return (
        <img
            {...props}
            src={srcMain}
            srcSet={srcSet.join(',')}
            alt={alt}
            loading={forceLoad ? 'eager' : 'lazy'}
        />
    )
}

export const LeafImageDynamic: React.FC<
    Omit<LeafImageDynamicProps, 'forceLoad'>
> = (props) => <LeafImageDynamicLazy {...props} forceLoad={true} />
