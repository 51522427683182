import { FC } from 'react'

import OutlineSVG from './Outline'
import VeinsSVG from './Veins'
import { LeafLogoSVGProps } from './type'

const LogoSilhouetteSVG: FC<LeafLogoSVGProps> = ({
    baseTransition,
    stopOnCompletion
}) => (
    <g id="veins" transform="translate(8.000000, 8.000000)">
        <OutlineSVG
            baseTransition={baseTransition}
            stopOnCompletion={stopOnCompletion}
        />
        <VeinsSVG
            baseTransition={baseTransition}
            stopOnCompletion={stopOnCompletion}
        />
    </g>
)

export default LogoSilhouetteSVG
