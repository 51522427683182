/* eslint-disable @thriveglobal/sentence-case/sentence-case */
import { TypographyOptions } from '@mui/material/styles/createTypography'

export const GTAmericaRg =
    'https://app-cdn.prod.thriveglobal.com/prod/fonts/GT-America-LCG-desktop/GT-America-LCG-Standard-Regular.otf'
export const GTAmericaMd =
    'https://app-cdn.prod.thriveglobal.com/prod/fonts/GT-America-LCG-desktop/GT-America-LCG-Standard-Medium.otf'
export const GTSuperDisplay =
    'https://app-cdn.prod.thriveglobal.com/prod/fonts/GT-Super-Full-desktop/GT-Super-Display-Medium.otf'

export const defaultTypography: TypographyOptions = {
    fontFamily: "'GT Super Display', 'GT America Md', 'GT America Rg'",
    h1: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        lineHeight: 1.08,
        letterSpacing: '-.15rem',
        '@media (max-width:600px)': {
            fontSize: '3.2rem',
            lineHeight: 1.25,
            letterSpacing: '-0.005rem'
        }
    },
    h2: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem',
        '@media (max-width:600px)': {
            fontSize: '2.8rem',
            lineHeight: 1.21
        }
    },
    h3: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2.4rem',
        lineHeight: 1.17,
        letterSpacing: '-0.0015rem'
    },
    h4: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2rem',
        lineHeight: 1.2,
        letterSpacing: '-0.0015rem'
    },
    h5: {
        fontFamily: 'GT America Md',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontSize: '1.6rem',
            lineHeight: 1.37
        }
    },
    h6: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.29,
        letterSpacing: '0.01rem',
        '@media (max-width:600px)': {
            fontSize: '1.2rem',
            lineHeight: 1.17
        }
    },
    caption: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            lineHeight: 1.5
        }
    },
    overline: {
        fontFamily: 'GT America Md',
        fontWeight: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.43,
        letterSpacing: '.12rem',
        '@media (max-width:600px)': {
            fontSize: '1.2rem',
            lineHeight: 1.5
        }
    },
    body1: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        lineHeight: 1.37,
        letterSpacing: '-0.0015rem'
    },
    body2: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.4rem',
        lineHeight: 1.43,
        letterSpacing: '-0.0015rem'
    }
}

export const customTypography = {
    stat1: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '8rem',
        lineHeight: 0.94,
        letterSpacing: '-.01rem'
    },
    stat2: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '5.6rem',
        lineHeight: 1.14,
        letterSpacing: '-0.005rem'
    },
    stat3: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem'
    },
    display1: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '9.6rem',
        lineHeight: 1.04,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontSize: '6.7rem',
            lineHeight: 1.1
        }
    },
    display2: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '4.8rem',
        lineHeight: 1.17,
        letterSpacing: '-0.005rem'
    },
    display3: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.01rem'
    },
    display4: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '3.2rem',
        lineHeight: 1.19,
        letterSpacing: '-0.0015rem',
        '@media (max-width:600px)': {
            fontWeight: 400,
            fontSize: '2.4rem',
            lineHeight: 1.25
        }
    },
    display5: {
        fontFamily: 'GT Super Display',
        fontFeatureSettings: "'pnum' on, 'lnum' on",
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.015rem'
    },
    buttonLarge: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem'
    },
    buttonNormal: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.6rem',
        lineHeight: 1.37,
        letterSpacing: '-0.0015rem'
    },
    buttonSmall: {
        fontFamily: 'GT America Md',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.2rem',
        lineHeight: 1.5,
        letterSpacing: '0.08em'
    },
    form: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.8rem',
        lineHeight: 1.33,
        letterSpacing: '-0.0015rem'
    },
    formBody: {
        fontFamily: 'GT America Rg',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '2.8rem',
        lineHeight: 1.29,
        letterSpacing: '-0.005rem'
    }
}
