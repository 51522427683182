import { CardProps, CardMediaProps, CardActionAreaProps } from '@mui/material'
import React, { ReactElement } from 'react'
import LeafBaseMediaCard from '../LeafBaseMediaCard'

interface LeafFlexMediaCardProps extends CardProps {
    media?: ReactElement<CardMediaProps>
    actionAreaProps?: CardActionAreaProps
}

const LeafFlexMediaCard: React.FC<LeafFlexMediaCardProps> = ({
    media,
    actionAreaProps,
    ...props
}) => {
    return (
        <LeafBaseMediaCard
            media={media}
            flexMedia
            actionAreaProps={actionAreaProps}
            alignMedia={'left'}
            mediaWidth={'fit-content'}
            {...props}
        >
            {props.children}
        </LeafBaseMediaCard>
    )
}

export default LeafFlexMediaCard
