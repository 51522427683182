import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import LeafBasePolicyLink from '../LeafBasePolicyLink'
import { localeToPrivacyPolicyMap } from '../LeafBasePolicyLink/constants/maps'
import { LeafExternalLinkProps } from '../../LeafExternalLink'

export const policyMessages = defineMessages({
    privacyPolicy: {
        defaultMessage: 'Privacy Policy',
        description: 'Link to our Privacy Policy'
    }
})

const LeafPrivacyPolicyLink: React.FC<LeafExternalLinkProps> = ({
    ...props
}) => {
    const intl = useIntl()

    return (
        <LeafBasePolicyLink
            linkText={intl.formatMessage(policyMessages.privacyPolicy)}
            linkMap={localeToPrivacyPolicyMap}
            {...props}
        />
    )
}

export default LeafPrivacyPolicyLink
