import { PropsWithChildren } from 'react'
import { Breakpoint, useMediaQuery } from '@mui/material'
import { useTheme } from '../../../../hooks'

interface PageLayoutProps {
    maxWidth?: Breakpoint
    style?: React.CSSProperties
}

const PageLayout: React.FC<PropsWithChildren<PageLayoutProps>> = ({
    maxWidth = 'lg',
    style = {},
    children
}) => {
    const theme = useTheme()
    const underMaxWidth = useMediaQuery(theme.breakpoints.down(maxWidth))

    const underSm = useMediaQuery(theme.breakpoints.down('sm'))

    function getPaddingX() {
        if (underMaxWidth) {
            if (underSm) {
                return theme.spacing(2)
            } else {
                return theme.spacing(6)
            }
        } else {
            return theme.spacing(2)
        }
    }

    return (
        <main
            style={{
                ...style,
                maxWidth: theme.breakpoints.values[maxWidth],
                width: '100%',
                padding: `0 ${getPaddingX()}`,
                margin: `0 ${underMaxWidth ? 'none' : 'auto'}`
            }}
        >
            {children}
        </main>
    )
}

export default PageLayout
