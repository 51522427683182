import { Box, Stack, useMediaQuery } from '@mui/material'
import { LeafDatePicker, LeafDatePickerProps } from '../LeafDatePicker'
import React, { ReactElement, useEffect, useState } from 'react'
import { LeafDateRangeToggle } from '../LeafDateRangeToggle'
import { LeafDateRangeTogglePresetProps } from '../../../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { useTheme } from '../../../../hooks'

interface LeafDateRangePickerProps {
    startDatePickerProps: Partial<LeafDatePickerProps>
    endDatePickerProps: Partial<LeafDatePickerProps>
    setStartDate: (string) => void
    setEndDate: (string) => void
    customInputActionBar?: ReactElement
    presets?: LeafDateRangeTogglePresetProps[]
}

const messages = defineMessages({
    startDateLabel: {
        defaultMessage: 'Start date',
        description: 'Label for start date input field.'
    },
    endDateLabel: {
        defaultMessage: 'End date',
        description: 'Label for end date input field.'
    }
})

export const LeafDateRangePicker: React.FC<LeafDateRangePickerProps> = ({
    startDatePickerProps,
    endDatePickerProps,
    setStartDate,
    setEndDate,
    presets,
    customInputActionBar
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

    const [selectedPreset, setSelectedPreset] =
        useState<LeafDateRangeTogglePresetProps>(null)

    const handleManualChange = (event) => {
        switch (event.target.id) {
            case 'start-date-picker-input':
                setStartDate(event.target.value)
                break
            case 'end-date-picker-input':
                setEndDate(event.target.value)
                break
        }
    }

    const [enableCustomInputs, setEnableCustomInputs] = useState<boolean>(true)

    useEffect(() => {
        setEnableCustomInputs(
            //if selected preset is not "custom"
            selectedPreset && selectedPreset.isCustom
        )
    }, [selectedPreset])

    return (
        <Stack direction={isMobileView ? 'column' : 'row'}>
            <LeafDateRangeToggle
                presets={presets}
                enableCustomPreset={true}
                orientation={'vertical'}
                color={'primary'}
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                sx={{
                    width: isMobileView ? 'auto' : 'max-content',
                    whiteSpace: 'nowrap'
                }}
            />
            <Stack
                direction={'column'}
                px={isMobileView ? 0 : 2}
                pt={isMobileView ? 4 : 0}
                width={'100%'}
            >
                <Stack direction={'column'} spacing={2} display={'flex'}>
                    <LeafDatePicker
                        type={'date'}
                        label={
                            startDatePickerProps.label
                                ? startDatePickerProps.label
                                : intl.formatMessage(messages.startDateLabel)
                        }
                        id={'start-date-picker-input'}
                        onChange={handleManualChange}
                        fullWidth
                        disabled={!enableCustomInputs}
                        aria-hidden={!enableCustomInputs}
                        variant={'outlined'}
                        {...startDatePickerProps}
                    />
                    <LeafDatePicker
                        type={'date'}
                        label={
                            endDatePickerProps.label
                                ? endDatePickerProps.label
                                : intl.formatMessage(messages.endDateLabel)
                        }
                        id={'end-date-picker-input'}
                        fullWidth
                        disabled={!enableCustomInputs}
                        aria-hidden={!enableCustomInputs}
                        variant={'outlined'}
                        onChange={handleManualChange}
                        {...endDatePickerProps}
                    />
                </Stack>
                <Box
                    height={'100%'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    {customInputActionBar}
                </Box>
            </Stack>
        </Stack>
    )
}
