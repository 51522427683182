import { ReactElement } from 'react'
import { Box, Divider, Typography, useTheme } from '@mui/material'

interface LeafMenuHeaderProps {
    title: string | ReactElement
}

const LeafMenuHeader: React.FC<LeafMenuHeaderProps> = ({ title }) => {
    const theme = useTheme()
    return (
        <Box minWidth={theme.spacing(40)}>
            <Typography padding={2} variant={'h3'}>
                {title}
            </Typography>
            <Divider aria-hidden="true" />
        </Box>
    )
}

export default LeafMenuHeader
