import React, { MutableRefObject, useCallback } from 'react'
import { Stream, StreamPlayerApi, StreamProps } from '@cloudflare/stream-react'
import { ClassNames } from '@emotion/react'
import { Box } from '@mui/material'

export type PlayerProps = StreamProps

function Player(props: PlayerProps, ref: MutableRefObject<StreamPlayerApi>) {
    const { src, onLoadStart, title = 'Reset player', ...restProps } = props

    const handleLoadStart = useCallback(
        (event: Event) => {
            const playerApi = ref?.current

            if (playerApi) {
                playerApi.autoplay = true
                playerApi.controls = true
                playerApi.preload = 'auto'
            }

            onLoadStart?.(event)
        },
        [ref, onLoadStart]
    )

    return (
        <Box
            sx={{ height: '100%', width: '100%' }}
            data-testid="PlayerContainer"
        >
            <ClassNames>
                {({ css, cx }) => (
                    <Stream
                        src={src}
                        height="100%"
                        width="100%"
                        controls
                        autoplay
                        responsive={false}
                        preload="auto"
                        streamRef={ref}
                        title={title}
                        className={cx(
                            css`
                                height: 100%;
                                width: 100%;
                            `
                        )}
                        {...restProps}
                        onLoadStart={handleLoadStart}
                    />
                )}
            </ClassNames>
        </Box>
    )
}

export default React.memo(
    React.forwardRef<StreamPlayerApi, PlayerProps>(Player)
)
