import {
    Box,
    CircularProgress,
    CircularProgressProps,
    styled,
    circularProgressClasses,
    Typography
} from '@mui/material'
import { useTheme } from '../../../../hooks'

const StyledCircularProgress: React.FC<CircularProgressProps> = styled(
    ({ size, ...props }: CircularProgressProps) => (
        <CircularProgress thickness={3} size={size ?? 64} {...props} />
    )
)(() => ({}))

export interface LeafCircularProgressProps extends CircularProgressProps {
    showLabel?: boolean
    label?: string
}

export const LeafCircularProgress: React.FC<LeafCircularProgressProps> = ({
    showLabel = false,
    label,
    value,
    ...props
}) => {
    const theme = useTheme()
    return (
        <>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <StyledCircularProgress
                    {...props}
                    aria-hidden="true"
                    value={100}
                    variant="determinate"
                    sx={{
                        color: theme.palette.grey[200]
                    }}
                />
                <StyledCircularProgress
                    disableShrink
                    sx={{
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round'
                        }
                    }}
                    value={value}
                    {...props}
                />
                {showLabel && (
                    <Typography
                        aria-hidden="true"
                        color={`${props.color}.main`}
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        variant="body1"
                    >
                        {label}
                    </Typography>
                )}
            </Box>
        </>
    )
}
export default LeafCircularProgress
