export * from './components'
export * from './hooks'
export * from './theme'
export * from './enums'
export * from './utils'

import './theme/extended-theme'

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
