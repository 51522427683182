import { StreamPlayerApi } from '@cloudflare/stream-react'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import Hls from 'hls.js' // TODO: extract `isSupported` from hls.js to a separate module to avoid importing the whole library
import React, { Suspense, useMemo } from 'react'
import { FullScreenLoading } from '../../../elements'
import IframePlayer, { IframePlayerProps } from './IframePlayer'
import PlayerContainer from './PlayerContainer'
import { getIframeVideoManifestSrc, parseIframeVideoSrcParams } from './utils'

export type IframePlayerWithHLSFallbackProps = IframePlayerProps

const IsFallbackResetPlayerEnabledFeatureFlag = 'IsFallbackResetPlayerEnabled'

const HLSPlayerLazy = React.lazy(() => import('./HLSPlayer'))

function IframePlayerWithHLSFallback(
    props: IframePlayerWithHLSFallbackProps,
    ref: React.ForwardedRef<StreamPlayerApi>
): JSX.Element {
    const { src, ...restProps } = props
    const isFallbackEnabled = useIsFeatureEnabled(
        IsFallbackResetPlayerEnabledFeatureFlag,
        false,
        true
    )

    const isFallback = useMemo(
        () => isFallbackEnabled && Hls.isSupported(),
        [isFallbackEnabled]
    )

    const manifestSrc = useMemo<string>(
        () => getIframeVideoManifestSrc(src),
        [src]
    )

    // Extract iframe video src params
    const iframeSrcParams = useMemo(() => parseIframeVideoSrcParams(src), [src])

    if (isFallback) {
        const { className, width, height, ...hlsPlayerProps } = restProps

        return (
            <PlayerContainer
                className={className}
                width={width}
                height={height}
            >
                <Suspense fallback={<FullScreenLoading />}>
                    <HLSPlayerLazy
                        ref={ref as React.ForwardedRef<HTMLVideoElement>}
                        src={manifestSrc}
                        {...hlsPlayerProps}
                        {...iframeSrcParams}
                    />
                </Suspense>
            </PlayerContainer>
        )
    } else {
        return <IframePlayer ref={ref} src={src} {...restProps} />
    }
}

export default React.memo(React.forwardRef(IframePlayerWithHLSFallback))
