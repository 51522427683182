import { SvgIcon } from '@mui/material'

const Library = ({ ...props }) => {
    return (
        <SvgIcon
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.45 3.14C2.45 2.75 2.765 2.45 3.14 2.45H10.25C11.555 2.45 12.605 3.5 12.62 4.79V23.375C11.21 22.1 9.38 21.395 7.475 21.395H3.14C2.75 21.395 2.45 21.08 2.45 20.705V3.14ZM12.89 26.39L11.525 25.025C10.46 23.96 9.005 23.345 7.49 23.345H3.14C1.685 23.345 0.5 22.16 0.5 20.705V3.14C0.5 1.685 1.685 0.5 3.14 0.5H10.25C11.6 0.5 12.8 1.115 13.595 2.09C14.39 1.115 15.59 0.5 16.94 0.5H24.05C25.505 0.5 26.69 1.685 26.69 3.14V20.705C26.69 22.16 25.505 23.345 24.05 23.345H19.715C18.2 23.345 16.745 23.945 15.68 25.025L14.315 26.39C14.225 26.48 14.105 26.555 13.985 26.615C13.865 26.66 13.73 26.69 13.61 26.69C13.49 26.69 13.355 26.66 13.235 26.615C13.115 26.57 12.995 26.48 12.905 26.39H12.89ZM14.57 23.375C15.98 22.1 17.81 21.395 19.715 21.395H24.05C24.44 21.395 24.74 21.08 24.74 20.705V3.14C24.74 2.75 24.425 2.45 24.05 2.45H16.94C15.635 2.45 14.585 3.5 14.57 4.79V23.375Z"
                fill="currentcolor"
            />
        </SvgIcon>
    )
}

export default Library
