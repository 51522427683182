import React, { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorScreenVariant, ErrorScreen } from '../ErrorScreen'
import { captureException } from '@thriveglobal/thrive-web-core'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
    error: Error
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: _ }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
        captureException(error)
    }

    public render() {
        if (this.state.hasError) {
            if (this.state.error.name === 'TypeError') {
                return <ErrorScreen variant={ErrorScreenVariant.General} />
            }
            if (this.state.error.name === 'ServerError') {
                return <ErrorScreen variant={ErrorScreenVariant.ServerError} />
            }
            if (this.state.error.name === 'AuthError') {
                return <ErrorScreen variant={ErrorScreenVariant.AuthError} />
            }
        }
        return this.props.children
    }
}

export default ErrorBoundary
