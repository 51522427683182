import React from 'react'
import { Breadcrumbs, BreadcrumbsProps } from '@mui/material'
import LeafIcon from '../../../foundations/icons'

export const LeafBreadcrumbs: React.FC<BreadcrumbsProps> = ({ ...props }) => {
    const { children, ...otherProps } = props
    return (
        <Breadcrumbs
            color="primary.text"
            separator={<LeafIcon icon={'angle-right'} fontSize={'small'} />}
            {...otherProps}
        >
            {children}
        </Breadcrumbs>
    )
}

export default LeafBreadcrumbs
