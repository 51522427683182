import { styled } from '@mui/material/styles'
import { OutlinedInput, OutlinedInputProps } from '@mui/material'
import { customTypography, HEIGHTS } from '../../../../theme'

const StyledMuiInput: React.ComponentType<OutlinedInputProps> = styled(
    OutlinedInput
)(({ theme }) => ({
    typography: customTypography.form
}))

export interface LeafDatePickerUnstyledProps extends OutlinedInputProps {
    type?: 'date' | 'datetime-local' | 'time' | undefined
}

export const LeafDatePickerUnstyled: React.FC<LeafDatePickerUnstyledProps> = ({
    type = 'date',
    ...props
}) => {
    return (
        <StyledMuiInput
            {...props}
            type={type}
            notched={true}
            inputProps={{
                ...props.inputProps,
                shrink: true
            }}
        />
    )
}
