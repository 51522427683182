import {
    Box,
    Button,
    Card,
    CardMedia,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '../../../../hooks'
import React, { useCallback } from 'react'
import { withTruncatedText } from '../../../elements/withTruncatedText'
import { CallToActionType } from './types'
import { CoreTypography } from '../../../elements/CoreTypography'
import { defaultImage } from './constants'
import ResetBannerBackground from './ResetBannerBackground'
import LeafIcon from '../../../foundations/icons'

export interface ResetProps {
    id?: string
    type: CallToActionType
    title: string
    eyebrow: string
    description: string
    image: string
    imageDescription?: string
    buttonText: string
}

export interface ResetBannerProps {
    reset: ResetProps
    displayed: boolean
    onAction: () => void
}

const TruncatedTypography = withTruncatedText(Typography)

const ResetBanner: React.FC<ResetBannerProps> = ({
    reset,
    displayed,
    onAction
}) => {
    const theme = useTheme()

    const isMobileView = useMediaQuery(theme.breakpoints.down('md'))

    const handleImageError = useCallback((e) => {
        e.target.onerror = null
        e.target.src = defaultImage
    }, [])

    return (
        <Box
            data-testid="ResetBanner"
            sx={{ display: 'inline-block', width: '100%' }}
            aria-hidden={!displayed}
        >
            <Card
                sx={[
                    {
                        position: 'relative',
                        color: theme.palette.primary.contrastText,
                        height: '32.5rem'
                    },
                    isMobileView && {
                        minHeight: '25rem'
                    }
                ]}
            >
                <ResetBannerBackground />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '100%',
                        p: theme.spacing(3),
                        position: 'relative'
                    }}
                >
                    <Stack
                        sx={{
                            width: '50%',
                            whiteSpace: 'normal',
                            paddingRight: theme.spacing(2)
                        }}
                    >
                        <Stack
                            height="100%"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Typography
                                variant="body2"
                                sx={[
                                    isMobileView && {
                                        fontSize: '1.2rem'
                                    }
                                ]}
                            >
                                {reset.eyebrow}
                            </Typography>

                            <Stack>
                                <TruncatedTypography
                                    textLines={2}
                                    variant="h3"
                                    title={reset.title}
                                    sx={[
                                        {
                                            marginBottom: theme.spacing(1),
                                            fontSize: '2.4rem'
                                        },
                                        isMobileView && {
                                            fontSize: '1.6rem'
                                        }
                                    ]}
                                >
                                    {reset.title}
                                </TruncatedTypography>
                                <TruncatedTypography
                                    variant="body2"
                                    textLines={4}
                                    title={reset.description}
                                >
                                    {reset.description}
                                </TruncatedTypography>
                            </Stack>

                            <Button
                                tabIndex={displayed ? 0 : -1}
                                size={'large'}
                                onClick={onAction}
                                variant="contained"
                                color="secondary"
                                sx={{
                                    pl: theme.spacing(isMobileView ? 1 : 4),
                                    pr: theme.spacing(isMobileView ? 2 : 6),
                                    py: theme.spacing(isMobileView ? 0 : 0.5)
                                }}
                            >
                                {reset.type ===
                                    CallToActionType.PlayThriveReset && (
                                    <LeafIcon icon={'play'} />
                                )}
                                <CoreTypography
                                    component="div"
                                    customVariant="buttonLarge"
                                >
                                    {reset.buttonText}
                                </CoreTypography>
                            </Button>
                        </Stack>
                    </Stack>
                    <CardMedia
                        component="img"
                        draggable={false}
                        role="img"
                        sx={{
                            height: '100%',
                            position: 'relative',
                            width: '50%',
                            objectFit: 'cover',
                            objectPosition: '50% 50%'
                        }}
                        onError={handleImageError}
                        image={reset.image}
                        alt={reset.imageDescription ?? null}
                    />
                </Box>
            </Card>
        </Box>
    )
}

export default React.memo(ResetBanner)
