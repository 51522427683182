import React from 'react'
import { Button, DialogActions, DialogContent } from '@mui/material'
import CoreTypography from '../../CoreTypography'
import LeafDialog, { LeafDialogProps } from '../LeafDialog'

interface LeafConfirmationDialogProps extends LeafDialogProps {
    dialogTitle: React.ReactElement | string
    dialogContent?: React.ReactElement | string
    primaryButtonText: string
    primaryButtonOnClick: () => void
    secondaryButtonText: string
    secondaryButtonOnClick: () => void
}

export const LeafConfirmationDialog: React.FC<LeafConfirmationDialogProps> = ({
    dialogTitle,
    dialogContent,
    primaryButtonOnClick,
    primaryButtonText,
    secondaryButtonOnClick,
    secondaryButtonText,
    ...props
}) => {
    const { children, ...otherProps } = props
    return (
        <LeafDialog {...otherProps} dialogTitle={dialogTitle}>
            <DialogContent>
                <CoreTypography variant={'body1'} color="text.primary">
                    {dialogContent}
                </CoreTypography>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    onClick={secondaryButtonOnClick}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {secondaryButtonText}
                    </CoreTypography>
                </Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={primaryButtonOnClick}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {primaryButtonText}
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default LeafConfirmationDialog
