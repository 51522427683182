import {
    Button,
    type ButtonProps,
    Stack,
    Tooltip,
    type TooltipProps,
    styled,
    alpha
} from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import { type MessageDescriptor, defineMessages, useIntl } from 'react-intl'
import IconButton from '../IconButton'
import { Z_INDEX } from '../../../../theme'
import { LeafIcon } from '../../../foundations'
import CoreTypography from '../../CoreTypography'
import { TOOLTIP_TIMEOUT, copyTextToClipboard } from './utils'
import { useTheme } from '../../../../hooks'

interface CopyButtonProps extends ButtonProps {
    linkBody: string
    ariaLabel?: string
    noLabel?: boolean
    label?: string
    noIcon?: boolean
    icon?: 'copy' | 'link'
    toolTipPosition?: 'top' | 'right' | 'left' | 'bottom'
    toolTipLabel?: string
    onCopied?: () => void
}

const StyledTooltip: React.FC<TooltipProps> = styled(
    ({ className, PopperProps, ...props }: TooltipProps) => (
        <Tooltip
            {...props}
            PopperProps={{
                disablePortal: true,
                style: { zIndex: Z_INDEX.BELOW_NAV }
            }}
            classes={{ popper: className }}
        />
    )
)(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        ...theme.typography.body2
    }
}))

const SRonlyText = styled('p')(() => ({
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden'
}))

const intlMessages: Record<string, MessageDescriptor> = defineMessages({
    copyToClipboard: {
        defaultMessage: 'Copy to clipboard',
        description: 'Label for the copy button'
    },
    success: {
        defaultMessage: 'Copied!',
        description: 'Message when copy action is successful'
    },
    copy: {
        defaultMessage: 'Copy',
        description: 'Label for the copy button'
    },
    linkLabel: {
        defaultMessage: 'Copy link',
        description: 'label for link that the user can copy and paste'
    },
    textLabel: {
        defaultMessage: 'Copy text',
        description: 'label for text that the user can copy and paste'
    }
})

const LeafCopyButton: React.FC<CopyButtonProps> = ({
    ariaLabel,
    linkBody,
    icon = 'copy',
    noLabel = false,
    label,
    noIcon = false,
    toolTipPosition = 'left',
    toolTipLabel,
    onCopied,
    ...props
}) => {
    const { palette } = useTheme()
    const { formatMessage } = useIntl()
    const [isCopySuccess, setCopySuccess] = useState(false)
    const contentContainerRef = useRef<HTMLDivElement>(null)

    const handleCopyText = useCallback(async () => {
        await copyTextToClipboard(linkBody, {
            container: contentContainerRef.current
        })
        onCopied?.()
        setCopySuccess(true)
        setTimeout(() => {
            setCopySuccess(false)
        }, TOOLTIP_TIMEOUT)
    }, [linkBody, onCopied])

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            role="region"
            aria-live="polite"
            ref={contentContainerRef}
        >
            <StyledTooltip
                open={isCopySuccess}
                title={toolTipLabel ?? formatMessage(intlMessages.success)}
                placement={toolTipPosition}
                PopperProps={{
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'preventOverflow',
                                enabled: false
                            },
                            { name: 'flip', enabled: false }
                        ]
                    }
                }}
            >
                {noLabel ? (
                    <IconButton
                        onClick={handleCopyText}
                        data-testid="copyToClipboard"
                        type="button"
                        color="primary"
                        aria-label={
                            ariaLabel ?? formatMessage(intlMessages.copy)
                        }
                        {...props}
                    >
                        <LeafIcon icon={icon} color={'inherit'} />
                    </IconButton>
                ) : (
                    <Button
                        data-testid="copyToClipboard"
                        onClick={handleCopyText}
                        variant="text"
                        color="primary"
                        aria-label={
                            ariaLabel ??
                            formatMessage(intlMessages.copyToClipboard)
                        }
                        {...(noIcon
                            ? {}
                            : {
                                  startIcon: (
                                      <LeafIcon icon={icon} color={'inherit'} />
                                  )
                              })}
                        {...props}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {label ?? formatMessage(intlMessages.copy)}
                        </CoreTypography>
                    </Button>
                )}
            </StyledTooltip>
            {isCopySuccess && (
                <SRonlyText>
                    {toolTipLabel ?? formatMessage(intlMessages.success)}
                </SRonlyText>
            )}
        </Stack>
    )
}

export default LeafCopyButton
