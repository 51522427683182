import { SvgIcon } from '@mui/material'

const Insights = ({ ...props }) => {
    return (
        <SvgIcon
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.9643 0.5C16.5614 0.5 17.8571 1.79516 17.8571 3.39286V24.6071C17.8571 26.2042 16.5614 27.5 14.9643 27.5H13.0357C11.4386 27.5 10.1429 26.2042 10.1429 24.6071V3.39286C10.1429 1.79516 11.4386 0.5 13.0357 0.5H14.9643ZM14.9643 2.42857H13.0357C12.5054 2.42857 12.0714 2.86009 12.0714 3.39286V24.6071C12.0714 25.1375 12.5054 25.5714 13.0357 25.5714H14.9643C15.4946 25.5714 15.9286 25.1375 15.9286 24.6071V3.39286C15.9286 2.86009 15.4946 2.42857 14.9643 2.42857ZM5.32143 12.0714C6.91853 12.0714 8.21429 13.3672 8.21429 14.9643V24.6071C8.21429 26.2042 6.91853 27.5 5.32143 27.5H3.39286C1.79516 27.5 0.5 26.2042 0.5 24.6071V14.9643C0.5 13.3672 1.79516 12.0714 3.39286 12.0714H5.32143ZM5.32143 14H3.39286C2.86009 14 2.42857 14.4339 2.42857 14.9643V24.6071C2.42857 25.1375 2.86009 25.5714 3.39286 25.5714H5.32143C5.8542 25.5714 6.28571 25.1375 6.28571 24.6071V14.9643C6.28571 14.4339 5.8542 14 5.32143 14ZM19.7857 7.25C19.7857 5.6529 21.0815 4.35714 22.6786 4.35714H24.6071C26.2042 4.35714 27.5 5.6529 27.5 7.25V24.6071C27.5 26.2042 26.2042 27.5 24.6071 27.5H22.6786C21.0815 27.5 19.7857 26.2042 19.7857 24.6071V7.25ZM21.7143 7.25V24.6071C21.7143 25.1375 22.1482 25.5714 22.6786 25.5714H24.6071C25.1375 25.5714 25.5714 25.1375 25.5714 24.6071V7.25C25.5714 6.71964 25.1375 6.28571 24.6071 6.28571H22.6786C22.1482 6.28571 21.7143 6.71964 21.7143 7.25Z"
                fill="currentcolor"
            />
        </SvgIcon>
    )
}

export default Insights
