import { PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

import { store as defaultStore } from '@thriveglobal/thrive-web-core'
import { defaultApplicationTheme, ExtendedTheme } from '../../theme'

type LeafProviderProps = {
    theme: ExtendedTheme
    store: typeof defaultStore
}

export const LeafProvider: React.FC<PropsWithChildren<LeafProviderProps>> = ({
    children,
    theme = defaultApplicationTheme,
    store = defaultStore
}) => (
    <MUIThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
            <IntlProvider locale="en-US" defaultLocale="en-US">
                <Provider store={store}>{children}</Provider>
            </IntlProvider>
        </EmotionThemeProvider>
    </MUIThemeProvider>
)
