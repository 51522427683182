import {
    memo,
    forwardRef,
    ComponentType,
    MutableRefObject,
    useCallback,
    useRef,
    useEffect
} from 'react'
import { StreamPlayerApi, StreamProps } from '@cloudflare/stream-react'
import { useVolume } from '@thriveglobal/thrive-web-core'

const MUTED_CHECK_INTERVAL = 500

export function withStreamVolume<TProps extends StreamProps>(
    StreamComponent: ComponentType<TProps>
) {
    return memo(
        forwardRef<StreamPlayerApi, TProps>(
            (props: TProps, ref: MutableRefObject<StreamPlayerApi>) => {
                const { autoplay, onVolumeChange, onLoadStart, onPlaying } =
                    props

                const [volume, setVolume] = useVolume()

                const internalRef = useRef<StreamPlayerApi>()
                const apiRef = ref ?? internalRef

                const volumeRef = useRef(volume)
                const mutedTimerRef = useRef(null)

                const handleVolumeChange = useCallback(
                    (event: Event) => {
                        const playerApi = apiRef?.current

                        // Stream API triggers 2 volumechange events, keep volume in ref to filter out duplicated events (useVolume cannot be used as it's updated async)
                        if (
                            playerApi &&
                            playerApi.volume !== volumeRef.current
                        ) {
                            onVolumeChange?.(event)
                            setVolume(playerApi.volume)
                            volumeRef.current = playerApi.volume
                        }
                    },
                    [setVolume, apiRef, onVolumeChange]
                )

                const handleLoadStart = useCallback(
                    (event: Event) => {
                        onLoadStart?.(event)

                        const playerApi = apiRef?.current

                        if (playerApi) {
                            // Workaround: enforce volume to be set on loadStart
                            playerApi.volume = volume
                        }
                    },
                    [apiRef, volume, onLoadStart]
                )

                const stopMutedTimer = useCallback(() => {
                    if (mutedTimerRef.current) {
                        clearInterval(mutedTimerRef.current)
                        mutedTimerRef.current = null
                    }
                }, [])

                // HACK: Stream API doesn't trigger volumechange event when stream is muted, so use interval to check manually if muted
                // HACK: if autoplay is enabled, start interval to check once onPlaying is triggered
                // (required to compensate mute -> play -> unmute hack in iFramePlayer.onLoadStart)
                const startMutedTimer = useCallback(() => {
                    stopMutedTimer()

                    mutedTimerRef.current = setInterval(() => {
                        if (apiRef.current?.muted && volumeRef.current > 0) {
                            volumeRef.current = 0
                            setVolume(0)
                        }
                    }, MUTED_CHECK_INTERVAL)
                }, [apiRef, setVolume, stopMutedTimer])

                const handlePlaying = useCallback(
                    (event: Event) => {
                        onPlaying?.(event)
                        if (autoplay && mutedTimerRef.current === null) {
                            startMutedTimer()
                        }
                    },
                    [onPlaying, autoplay, startMutedTimer]
                )

                // if autoplay is disabled - start muted timer immediately
                useEffect(() => {
                    if (!autoplay && mutedTimerRef.current === null) {
                        startMutedTimer()
                    }
                }, [startMutedTimer, autoplay])

                // use separate effect to dispose timer to prevent unsubscribing when autoplay changes
                useEffect(() => {
                    return () => {
                        stopMutedTimer()
                    }
                }, [stopMutedTimer])

                return (
                    <StreamComponent
                        {...props}
                        ref={apiRef}
                        volume={volume}
                        onLoadStart={handleLoadStart}
                        onVolumeChange={handleVolumeChange}
                        onPlaying={handlePlaying}
                    />
                )
            }
        )
    )
}

export default withStreamVolume
