import { Box, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { default as Confetti } from 'react-confetti-explosion'

export interface IConfetti {
    particleCount?: number
    duration?: number
    colors?: string[]
    particleSize?: number
    force?: number
    floorHeight?: number
    floorWidth?: number
}

export type ConfettiExplosionProps = {
    show: boolean
    size?: 'small' | 'medium' | 'large'
    onComplete?: () => void
    props?: IConfetti
}

const ConfettiExplosion: React.FC<ConfettiExplosionProps> = ({
    show,
    size = 'medium',
    onComplete,
    props
}) => {
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )
    const [triggered, setTriggered] = useState(false)
    const defaultSize = useMemo(() => {
        return {
            ['small']: {
                force: 0.4,
                duration: 2000,
                particleCount: 30,
                floorHeight: 500,
                floorWidth: 300,
                colors: ['#1E0056', '#3FA3B0', '#14808E', '#4D1AAD']
            },
            ['medium']: {
                force: 0.4,
                duration: 3000,
                particleCount: 70,
                floorHeight: 1000,
                floorWidth: 500,
                colors: ['#1E0056', '#3FA3B0', '#14808E', '#4D1AAD']
            },
            ['large']: {
                force: 0.6,
                duration: 5000,
                particleCount: 200,
                floorHeight: 1600,
                floorWidth: 1600,
                colors: ['#1E0056', '#3FA3B0', '#14808E', '#4D1AAD']
            }
        }
    }, [])

    const selectedProps = useMemo(() => {
        if (props) {
            return { ...defaultSize[size], ...props }
        }

        return defaultSize[size]
    }, [defaultSize, size, props])

    useEffect(() => {
        if (show) {
            setTriggered(true)
            setTimeout(() => {
                if (onComplete) {
                    onComplete()
                    setTriggered(false)
                }
            }, selectedProps.duration)
        }
    }, [show, selectedProps, onComplete])

    return useMemo(
        () => (
            <>
                {triggered && !prefersReducedMotion && (
                    <Box data-testid="confetti-explosion">
                        <Confetti {...selectedProps} />
                    </Box>
                )}
            </>
        ),
        [selectedProps, triggered, prefersReducedMotion]
    )
}

export default ConfettiExplosion
