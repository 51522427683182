import { SvgIcon, SvgIconProps } from '@mui/material'
import {
    IconName,
    IconStyle,
    IconFamily
} from '@fortawesome/fontawesome-common-types'

export interface LeafIconProps extends SvgIconProps {
    icon: IconName
    iconStyle?: IconStyle
    iconFamily?: IconFamily
}

const LeafIcon: React.FC<LeafIconProps> = ({
    icon,
    iconStyle = 'regular',
    iconFamily,
    ...props
}) => {
    const iconClassName =
        'fa-' +
        iconStyle +
        ' fa-' +
        icon +
        (iconFamily ? ' fa-' + iconFamily : '')

    return (
        <SvgIcon {...props} key={iconClassName.replace(' ', '-')}>
            <i className={iconClassName} data-testid={'fa-icon'} />
        </SvgIcon>
    )
}

export default LeafIcon
