import { Box, BoxProps } from '@mui/material'

interface LeafScrimProps extends BoxProps {
    leftScrim?: boolean
    leftScrimMaxWidth?: string
    borderRadius?: string | number
}

const LeafScrim: React.FC<LeafScrimProps> = ({
    leftScrim = false,
    leftScrimMaxWidth = '75%',
    borderRadius = 0,
    ...props
}) => {
    const imageListItemBarStyles = leftScrim
        ? {
              top: 0,
              left: 0,
              bottom: 0,
              right: 'inherit',
              background: `linear-gradient(90.2deg, rgba(0, 0, 0, 0.84) 0.18%, rgba(0, 0, 0, 0.72) 70.24%, rgba(0, 0, 0, 0.44) 80.1%, rgba(0, 0, 0, 0.200478) 89.44%, rgba(0, 0, 0, 0) 99.82%)`,
              maxWidth: leftScrimMaxWidth,
              borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
              '.MuiImageListItemBar-titleWrap': {
                  paddingRight: '30%',
                  py: 0
              }
          }
        : {}

    return (
        <Box
            sx={{
                img: {
                    borderRadius: borderRadius
                },
                '.MuiImageListItemBar-subtitle': {
                    whiteSpace: 'unset'
                },
                '.MuiImageListItemBar-title': {
                    whiteSpace: 'unset'
                },
                '.MuiImageListItemBar-positionTop': {
                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 25.52%, rgba(0, 0, 0, 0.72) 48.96%, rgba(0, 0, 0, 0.84) 100%)`,
                    borderRadius: `${borderRadius} ${borderRadius} 0 0`,
                    '.MuiImageListItemBar-titleWrap': {
                        paddingBottom: '25%'
                    }
                },
                '.MuiImageListItemBar-positionBottom': {
                    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 13.54%, rgba(0, 0, 0, 0.44) 27.6%, rgba(0, 0, 0, 0.72) 41.67%, rgba(0, 0, 0, 0.84) 100%)`,
                    borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
                    '.MuiImageListItemBar-titleWrap': {
                        paddingTop: '25%'
                    }
                },
                '.MuiImageListItemBar-root': imageListItemBarStyles
            }}
            {...props}
        />
    )
}

export default LeafScrim
