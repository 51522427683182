import React from 'react'
import { Box } from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    markLogoWhite: {
        defaultMessage: 'Single white leaf logo.',
        description: 'Alt text for Thrive Logo'
    },
    markLogoFlat: {
        defaultMessage: 'Single teal leaf logo.',
        description: 'Alt text for Thrive Logo'
    },
    markLogoMulti: {
        defaultMessage: 'Single multi-colored teal leaf logo.',
        description: 'Alt text for Thrive Logo'
    }
})

export const MarkLogoWhite = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="markLogoWhite">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-Mark-1Color-OnDark.svg`}
                alt={intl.formatMessage(messages.markLogoWhite)}
                height={'100%'}
            />
        </Box>
    )
}

export const MarkLogoFlat = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="markLogoFlat">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-Mark-Flat.svg`}
                alt={intl.formatMessage(messages.markLogoFlat)}
                height={'100%'}
            />
        </Box>
    )
}

export const MarkLogoMulti = (props: any) => {
    const intl = useIntl()
    return (
        <Box {...props} data-testid="markLogoMulti">
            <img
                src={`${process.env.CDN_ASSETS}/company_logos/Thrive-Mark-Multi.svg`}
                alt={intl.formatMessage(messages.markLogoMulti)}
                height={'100%'}
            />
        </Box>
    )
}
