import { Locale } from '@thriveglobal/thrive-web-core'

export const localeToPrivacyPolicyMap: {
    [K in Locale]: string
} = {
    'en-US': 'https://thriveglobal.com/privacy',
    'es-419': 'https://thriveglobal.com/privacy-es',
    'fr-CA': 'https://thriveglobal.com/privacy-fr',
    'fr-FR': 'https://thriveglobal.com/privacy-fr',
    'it-IT': 'https://thriveglobal.com/privacy',
    'de-DE': 'https://thriveglobal.com/privacy',
    'pt-BR': 'https://thriveglobal.com/privacy',
    'ja-JP': 'https://thriveglobal.com/privacy',
    'zh-Hans': 'https://thriveglobal.com/privacy',
    'el-GR': 'https://thriveglobal.com/privacy',
    pseudo: 'https://thriveglobal.com/privacy'
}

export const localeToToSMap: {
    [K in Locale]: string
} = {
    'en-US': 'https://thriveglobal.com/terms',
    'es-419': 'https://thriveglobal.com/terms-es',
    'fr-CA': 'https://thriveglobal.com/terms-fr',
    'fr-FR': 'https://thriveglobal.com/terms-fr',
    'it-IT': 'https://thriveglobal.com/terms',
    'de-DE': 'https://thriveglobal.com/terms',
    'pt-BR': 'https://thriveglobal.com/terms',
    'ja-JP': 'https://thriveglobal.com/terms',
    'zh-Hans': 'https://thriveglobal.com/terms',
    'el-GR': 'https://thriveglobal.com/terms',
    pseudo: 'https://thriveglobal.com/terms'
}
