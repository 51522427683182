import { useEffect, useState } from 'react'
import {
    defaultApplicationTheme,
    getAllThemes,
    ExtendedTheme
} from '../../theme'
import { store, useAppSelector } from '@thriveglobal/thrive-web-core'

export const useTheme = (): ExtendedTheme => {
    const themes = getAllThemes()
    const settings = useAppSelector((state: any) => state.settings)

    // some test suites mock core's appSelector for good reasons. if
    // those engineers don't know that leafkit needs a settings.theme,
    // they may not provide one. fault tolerance ftw!
    const [theme, setTheme] = useState<ExtendedTheme>(
        themes[settings?.theme]
            ? themes[settings.theme]
            : defaultApplicationTheme
    )

    useEffect(() => {
        if (themes[settings.theme]) {
            setTheme(themes[settings.theme])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.theme])
    return theme
}

//For exclusive use for ThriveApplication component - redux store initialization async error
export const useThriveApplicationTheme = (): ExtendedTheme => {
    const themes = getAllThemes()
    const initialSettings = store.getState().settings
    const [theme, setTheme] = useState<ExtendedTheme>(
        themes ? themes[initialSettings.theme] : defaultApplicationTheme
    )
    const [themeName, setThemeName] = useState<string>(initialSettings.theme)

    useEffect(() => {
        store.subscribe(async () => {
            const settings = store.getState().settings

            if (themeName !== settings.theme) {
                setTheme(themes[settings.theme])
                setThemeName(settings.theme)
            }
        })
    }, [])

    return theme
}
