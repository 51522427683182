import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Link,
    LinkProps,
    Typography,
    useMediaQuery
} from '@mui/material'
import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { LeafDialog } from '../../index'
import { useTheme } from '../../../../hooks'
import CoreTypography from '../../CoreTypography'
import LeafIcon from '../../../foundations/icons'

export interface LeafExternalLinkProps extends LinkProps {
    externalLink?: string
    externalHref?: string
    hideIcon?: boolean
    iconFontSize?: 'inherit' | 'large' | 'medium' | 'small'
    onContinue?: () => void
}

const messages = defineMessages({
    externalLinkConfirmationText: {
        defaultMessage:
            'This link will open outside of the Thrive application.',
        description:
            "Text describing what will happen when user clicks 'Continue'"
    },
    externalLinkButtonText: {
        defaultMessage: 'Continue',
        description: 'label for continue to external link button'
    }
})

const LeafExternalLink: React.FC<LeafExternalLinkProps> = ({
    externalLink,
    externalHref,
    hideIcon = false,
    iconFontSize = 'inherit',
    onContinue,
    ...props
}) => {
    const intl = useIntl()
    const theme = useTheme()
    const [confirmationDialogOpen, setConfirmationDialogOpen] =
        useState<boolean>(false)
    const dialogWidth = 'sm'
    const fullWidth = useMediaQuery(theme.breakpoints.down(dialogWidth))
    const {
        onClick,
        href,
        children,
        display,
        padding,
        paddingRight,
        paddingLeft,
        paddingTop,
        paddingBottom,
        paddingX,
        paddingY,
        margin,
        marginRight,
        marginLeft,
        marginTop,
        marginBottom,
        marginX,
        marginY,
        ...otherProps
    } = props

    const wrapperProps = {
        display,
        padding,
        paddingRight,
        paddingLeft,
        paddingTop,
        paddingBottom,
        paddingX,
        paddingY,
        margin,
        marginRight,
        marginLeft,
        marginTop,
        marginBottom,
        marginX,
        marginY
    }

    function openDialog(e) {
        if (e.type !== 'keydown' || e.key === 'Enter' || e.key === 'Space') {
            setConfirmationDialogOpen(true)
            onClick?.(e)
        }
        if (e.key === 'Tab') {
            e.stopPropagation()
        }
    }

    function closeDialog() {
        setConfirmationDialogOpen(false)
    }

    function handleContinueClicked() {
        if (externalLink) {
            onContinue?.()
            window.open(externalLink)
            closeDialog()
        }
    }

    return (
        <Box onKeyDown={openDialog} {...wrapperProps}>
            <Link
                {...otherProps}
                onClick={openDialog}
                display={'flex'}
                sx={{ ...otherProps.sx, cursor: 'pointer' }}
            >
                {props.children}
                {!hideIcon && (
                    <LeafIcon
                        icon={'arrow-up-right-from-square'}
                        fontSize={iconFontSize}
                        sx={{ ml: 1, alignSelf: 'center' }}
                    />
                )}
            </Link>
            <LeafDialog
                id={'external-link-confirmation-dialog'}
                fullWidth={fullWidth}
                open={confirmationDialogOpen}
                onClose={closeDialog}
                maxWidth={dialogWidth}
                sx={{
                    zIndex: 10000
                }}
                PaperProps={{
                    sx: {
                        width: theme.breakpoints.values[dialogWidth],
                        p: 2
                    }
                }}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Typography sx={{ mx: 'auto' }}>
                        {intl.formatMessage(
                            messages.externalLinkConfirmationText
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'center' }}>
                    <Button
                        component={Link}
                        onClick={handleContinueClicked}
                        href={externalHref}
                        variant={'contained'}
                        color={'primary'}
                        size={'large'}
                        endIcon={
                            <LeafIcon icon={'arrow-right'} fontSize={'small'} />
                        }
                    >
                        <CoreTypography customVariant={'buttonLarge'}>
                            {intl.formatMessage(
                                messages.externalLinkButtonText
                            )}
                        </CoreTypography>
                    </Button>
                </DialogActions>
            </LeafDialog>
        </Box>
    )
}

export default LeafExternalLink
