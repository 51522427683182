import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '../../../../hooks'

interface LeafPageHeaderProps {
    title: string
    navigation?: React.ReactNode
    action?: React.ReactNode
}

const LeafPageHeader: React.FC<LeafPageHeaderProps> = ({
    title,
    action,
    navigation
}) => {
    const theme = useTheme()
    return (
        <>
            <Grid
                container
                spacing={0}
                sx={{
                    py: theme.spacing(3),
                    [theme.breakpoints.down('sm')]: {
                        pt: theme.spacing(2)
                    }
                }}
            >
                {!!navigation && (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            pt: 0,
                            pb: theme.spacing(2)
                        }}
                    >
                        {navigation}
                    </Grid>
                )}
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    flexDirection={'row'}
                    columnSpacing={2}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h1" color="text.primary">
                            {title}
                        </Typography>
                    </Grid>
                    {action && (
                        <Grid item xs={'auto'}>
                            <Stack>{action}</Stack>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default LeafPageHeader
