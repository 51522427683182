import { FC } from 'react'
import { motion } from 'framer-motion'
import { LeafLogoSVGProps } from './type'

const pathLength = 2015.1597900390625

const OutlineSVG: FC<LeafLogoSVGProps> = ({
    baseTransition,
    stopOnCompletion
}) => (
    <motion.path
        id="outline"
        d="M315.38673,698 C407.213565,693 608,472.127376 608,304.149371 C608,136.172378 470.735894,0 301.41296,0 C132.089014,0 0,136.172378 0,304.149371 C0,472.127376 206.9784,693 301.41296,698 L304,698 L310.38673,698 Z"
        stroke="currentColor"
        strokeWidth="30"
        strokeLinecap="round"
        fillRule="nonzero"
        transform="translate(304.000000, 346.500000) scale(-0.96, 0.97) translate(-304.000000, -344.500000) "
        initial={{
            strokeDasharray: pathLength,
            strokeDashoffset: pathLength,
            opacity: 1
        }}
        animate={{
            opacity: stopOnCompletion
                ? [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
                : [1, 1, 1, 1, 1, 1, 1, 1, 0.8, 0],
            strokeDashoffset: stopOnCompletion
                ? [pathLength * 3, pathLength * 2, pathLength * 2]
                : [pathLength * 3, pathLength * 2, pathLength],
            transition: { ...baseTransition }
        }}
    ></motion.path>
)

export default OutlineSVG
