import { SvgIcon } from '@mui/material'
import { useTheme } from '../../../../hooks'
import React from 'react'

const ResetBannerBackground: React.FC = () => {
    const theme = useTheme()

    return (
        <SvgIcon
            width="872"
            height="248"
            viewBox="0 0 872 248"
            fill="none"
            preserveAspectRatio="none"
            sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                color: theme.palette.primary.main,

                zIndex: 0,
                [theme.breakpoints.down('md')]: {
                    left: 0,
                    transform: 'scale(-3, 1)'
                }
            }}
        >
            <rect width="872" height="248" fill="currentColor" />
            <path
                opacity="0.1"
                d="M951.977 299.489C951.977 249.916 942.213 200.829 923.243 155.03C904.272 109.231 876.466 67.6169 841.413 32.5639C806.36 -2.4892 764.746 -30.2948 718.947 -49.2654C673.148 -68.236 624.061 -78 574.489 -78C524.916 -78 475.829 -68.236 430.03 -49.2654C384.231 -30.2948 342.617 -2.48918 307.564 32.5639C272.511 67.6169 244.705 109.231 225.735 155.03C206.764 200.829 197 249.916 197 299.489L574.489 299.489H951.977Z"
                fill="#C4C4C4"
            />
            <path
                opacity="0.1"
                d="M832.335 300.337C832.335 266.149 825.657 232.296 812.683 200.71C799.709 169.125 780.693 140.425 756.72 116.251C732.746 92.0764 704.286 72.9001 672.964 59.817C641.642 46.7338 608.071 40 574.167 40C540.264 40 506.693 46.7338 475.371 59.817C444.049 72.9001 415.589 92.0764 391.616 116.251C367.642 140.425 348.626 169.125 335.652 200.71C322.678 232.296 316 266.149 316 300.337L574.168 300.337H832.335Z"
                fill="#C4C4C4"
            />
            <path
                opacity="0.1"
                d="M721.049 298.609C721.049 259.195 705.506 221.396 677.84 193.527C650.174 165.657 612.65 150 573.524 150C534.398 150 496.875 165.657 469.209 193.527C441.543 221.396 426 259.195 426 298.609L573.524 298.609H721.049Z"
                fill="#C4C4C4"
            />
        </SvgIcon>
    )
}

export default React.memo(ResetBannerBackground)
