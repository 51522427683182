/* eslint-disable @thriveglobal/sentence-case/sentence-case */

import { Box, Stack } from '@mui/material'
import React from 'react'
import { LeafExternalLink } from '../../elements'
import { defineMessages, useIntl } from 'react-intl'
import { LeafErrorCode } from '../index'
import { isRunningInWebview } from '@thriveglobal/thrive-web-core'
import PageLayout from '../../patterns/new-page-setup/PageLayout'
import { LeafErrorCodeProps } from '../LeafErrorCode'

const messages = defineMessages({
    contactUsLink: {
        defaultMessage: 'Contact us',
        description: 'Text for link to contact us.'
    },
    webAppSupportSubject: {
        defaultMessage: 'Web app support',
        description: 'Subject for email to support from web'
    },
    mobileAppSupportSubject: {
        defaultMessage: 'Mobile app support',
        description: 'Subject for email to support from hybrid mobile'
    }
})

export enum ErrorScreenVariant {
    General = 'General',
    ServerError = 'ServerError',
    AuthError = 'AuthError'
}

export interface ErrorData {
    title: string
    subtitle: string
    author: string
    altText: string
    image: string
}

export interface ErrorScreenProps extends LeafErrorCodeProps {
    variant: ErrorScreenVariant
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
    variant,
    ...errorCodeProps
}) => {
    const intl = useIntl()

    return (
        <PageLayout style={{ height: 'inherit' }}>
            <Stack height={'100%'} pt={4}>
                <LeafErrorCode variant={variant} {...errorCodeProps} />
                <Box my={5} mt={'auto'}>
                    <LeafExternalLink
                        justifyContent={'center'}
                        externalHref={`mailto:support@thriveglobal.com?subject=${intl.formatMessage(
                            !isRunningInWebview()
                                ? messages.webAppSupportSubject
                                : messages.mobileAppSupportSubject
                        )}`}
                    >
                        {intl.formatMessage(messages.contactUsLink)}
                    </LeafExternalLink>
                </Box>
            </Stack>
        </PageLayout>
    )
}

export default ErrorScreen
