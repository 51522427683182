import { useEffect, useState } from 'react'

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEffect(() => {
        let rafId: number

        const handleWindowResize = () => {
            if (rafId) {
                cancelAnimationFrame(rafId)
            }

            rafId = requestAnimationFrame(() => {
                setWindowWidth(window.innerWidth)
            })
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
            if (rafId) {
                cancelAnimationFrame(rafId)
            }
        }
    }, [])

    return windowWidth
}

export default useWindowWidth
