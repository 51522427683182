export const imageConfig = {
    srcSetWidths: [420, 640, 768, 1024, 1366, 1600, 1920],
    srcSetDefaultWidth: 1024,
    placeholderImage:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4105556d-35d1-425c-c729-8dfcb9654200/cms'
}

export function maxImageWidth(maxWidth: number | null) {
    const useWidths = []

    imageConfig.srcSetWidths.forEach((w) => {
        if (!maxWidth || w <= maxWidth) {
            useWidths.push(w)
        }
    })

    if (useWidths.length === 0) {
        return imageConfig.srcSetWidths[0]
    }

    useWidths.sort((a, b) => a - b)

    return useWidths.pop()
}
