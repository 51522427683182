import React, { useCallback, useEffect, useState } from 'react'
import BurstAnimation, { BurstAnimationProps } from '../BurstAnimation'

export type BurstNotificationProps = {
    show: boolean
    skipAnimation?: boolean
    softenText?: boolean
    alwaysVisible?: boolean
    onCompleteAction?: () => void
    text?: string
    size?: number
    color?: string
    duration?: number
    children?: React.ReactNode
}

export const BurstNotification: React.FC<BurstNotificationProps> = ({
    show,
    skipAnimation = false,
    softenText,
    alwaysVisible = false,
    onCompleteAction,
    text,
    size = 14,
    color,
    duration = 2000,
    children
}) => {
    const [onShow, setOnShow] = useState(show)

    const completeAction = useCallback(() => {
        onCompleteAction
            ? onCompleteAction()
            : (() => setOnShow(alwaysVisible))()
    }, [onCompleteAction, alwaysVisible])

    useEffect(() => {
        setOnShow(show)
    }, [show])

    return (
        <>
            {onShow && (
                <BurstAnimation
                    skipAnimation={skipAnimation}
                    softenText={softenText}
                    completeAction={completeAction}
                    text={text}
                    size={size}
                    color={color}
                    duration={duration}
                    children={children}
                />
            )}
        </>
    )
}

export default React.memo(BurstNotification)
