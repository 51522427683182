import { Alert, AlertProps, styled } from '@mui/material'
import React from 'react'

interface CoreAlertProps extends AlertProps {
    verticalOrientation?: boolean
    actionPositionEnd?: boolean
}

const CoreAlert: React.ComponentType<CoreAlertProps> = styled(
    Alert
)<CoreAlertProps>(
    ({
        verticalOrientation = false,
        actionPositionEnd = false,
        theme,
        action
    }) => {
        return {
            width: '100%',
            padding: theme.spacing(3),
            '& .MuiAlert-icon': {
                height: 'auto',
                minHeight: '3.6rem',
                padding: 0,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                fontSize: 36
            },
            '& .MuiAlertTitle-root': {
                textAlign: 'left',
                marginBottom: '0.3rem'
            },
            '& .MuiAlert-action': {
                whiteSpace: 'nowrap',
                '.svg-inline--fa': {
                    fontSize: 'x-large'
                }
            },
            '& .MuiAlert-message': {
                padding: '0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                minHeight: '3.6rem',
                textAlign: 'left',
                width: '100%'
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2)
            },
            ...(verticalOrientation
                ? {
                      //vertical orientation styles
                      display: actionPositionEnd ? 'flex' : 'table',
                      flexDirection: 'column',
                      '.MuiAlert-icon': {
                          margin: theme.spacing(0, 0, 1),
                          float: 'left',
                          alignSelf: 'flex-start'
                      },
                      '.MuiAlert-message': actionPositionEnd
                          ? {}
                          : {
                                display: 'table-footer-group !important'
                            },
                      '.MuiAlert-action': actionPositionEnd
                          ? {
                                marginLeft: 0,
                                marginTop: theme.spacing(1),
                                padding: 0,
                                whiteSpace: 'initial',
                                alignSelf: 'flex-end'
                            }
                          : {
                                marginLeft: 0,
                                marginTop: '-50px',
                                padding: 0,
                                float: 'right',
                                whiteSpace: 'initial'
                            }
                  }
                : {}),
            //  TODO: add custom overwrite for dark mode without these gradients i think
            // Success
            '&&.MuiAlert-standardSuccess': {
                background: theme.palette.success.light,
                '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                    {
                        color: theme.palette.success.dark
                    }
            },
            // Error
            '&&.MuiAlert-standardError': {
                background: theme.palette.error.light,
                '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                    {
                        color: theme.palette.error.dark
                    }
            },
            // Warning
            '&&.MuiAlert-standardWarning': {
                background: theme.palette.warning.light,
                '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                    {
                        color: theme.palette.warning.dark
                    }
            },
            // Info
            '&&.MuiAlert-standardInfo': {
                background: theme.palette.grey[100],
                '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                    {
                        color: theme.palette.info.contrastText
                    }
            },
            // Outlined
            '&&.MuiAlert-outlined': {
                borderColor: theme.palette.background.paper,
                '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                    {
                        color: theme.palette.background.paper
                    }
            },
            // Filled
            '&&.MuiAlert-filled': {
                backgroundColor: theme.palette.background.paper,
                // Success
                '&&.MuiAlert-filledSuccess': {
                    '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                        {
                            color: theme.palette.success.dark
                        }
                },
                // Error
                '&&.MuiAlert-filledError': {
                    '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                        {
                            color: theme.palette.error.dark
                        }
                },
                // Warning
                '&&.MuiAlert-filledWarning': {
                    '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                        {
                            color: theme.palette.warning.dark
                        }
                },
                // Info
                '&&.MuiAlert-filledInfo': {
                    '& .MuiAlertTitle-root, .MuiAlert-message, .svg-inline--fa, .MuiSvgIcon-root, .MuiAlert-action':
                        {
                            color: theme.palette.info.contrastText
                        }
                }
            }
        }
    }
)

export default CoreAlert
