import { Accordion, AccordionProps, styled } from '@mui/material'

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: 'inherit',
    color: theme.palette.text.primary,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    },
    '& .MuiAccordionDetails-root': {
        marginLeft: theme.spacing(5)
    }
}))
export const LeafAccordion: React.FC<AccordionProps> = ({
    children,
    ...props
}) => {
    return (
        <>
            <StyledAccordion disableGutters elevation={0} square {...props}>
                {children}
            </StyledAccordion>
        </>
    )
}
export default LeafAccordion
