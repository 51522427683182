import React from 'react'
import { Typography } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography/Typography'
import { customTypography } from '../../../theme'
import { useIntl, FormattedMessage } from 'react-intl'

export interface CoreTypographyProps
    extends TypographyProps<
        React.ElementType,
        { component?: React.ElementType }
    > {
    customVariant?:
        | 'buttonLarge'
        | 'buttonNormal'
        | 'buttonSmall'
        | 'form'
        | 'formBody'
        | 'stat1'
        | 'stat2'
        | 'stat3'
        | 'display1'
        | 'display2'
        | 'display3'
        | 'display4'
        | 'display5'
}

export const CoreTypography: React.FC<CoreTypographyProps> = ({
    sx,
    variant,
    customVariant,
    children,
    uppercase,
    ...otherProps
}) => {
    const variantFromTheme = customVariant
        ? customTypography[customVariant]
        : undefined
    const customSx = sx ? { ...sx, ...variantFromTheme } : variantFromTheme
    const intl = useIntl()
    const { formatMessage } = useIntl()
    const locale = intl.locale
    const uppercaseVariant =
        uppercase || variant === 'overline' || customVariant === 'buttonSmall'
    const childArr = React.Children.toArray(children)

    return (
        <Typography
            sx={customSx}
            variant={customVariant ? undefined : variant}
            {...otherProps}
        >
            {uppercaseVariant
                ? childArr.map((child: string | React.ReactElement<any>) => {
                      if (typeof child === 'string') {
                          return child.toLocaleUpperCase(locale)
                      } else if (child.type === FormattedMessage) {
                          const messageDesc = {
                              id: child.props?.id,
                              description: child.props?.description,
                              defaultMessage: child.props.defaultMessage
                          }
                          const formattedMessageChunks = React.Children.toArray(
                              formatMessage(messageDesc, child.props?.values)
                          )
                          return formattedMessageChunks.map((messageChunk) =>
                              typeof messageChunk === 'string'
                                  ? messageChunk.toLocaleUpperCase(locale)
                                  : messageChunk
                          )
                      } else return children
                  })
                : children}
        </Typography>
    )
}

export default CoreTypography
