import React from 'react'
import { SvgIcon } from '@mui/material'

const ThriveLogoSingleLeafOnDark = () => {
    return (
        <SvgIcon
            viewBox="0 0 161 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
                height: '100%',
                width: '100%',
                maxWidth: '180px',
                margin: '0'
            }}
        >
            <image
                width={'100%'}
                xlinkHref={`${process.env.CDN_ASSETS}/company_logos/Thrive-FullLogo-Flat-OnDark.svg`}
            />
        </SvgIcon>
    )
}

export default ThriveLogoSingleLeafOnDark
