import CoreTypography from '../../CoreTypography'
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material'
import React, { useEffect } from 'react'
import {
    leafDateRangePickerPresets,
    LeafDateRangeProps,
    LeafDateRangeTogglePresetProps
} from '../../../../utils'
import { defineMessages, useIntl } from 'react-intl'
import { LeafToggleButton } from '../..'

interface LeafDateRangeToggleProps extends ToggleButtonGroupProps {
    orientation?: 'vertical' | 'horizontal'
    color?: 'primary' | 'secondary'
    presets?: LeafDateRangeTogglePresetProps[]
    selectedPreset: LeafDateRangeTogglePresetProps
    setSelectedPreset: (LeafDateRangeTogglePresetProps) => void
    setStartDate: (string) => void
    setEndDate: (string) => void
    setStartTime?: (string) => void
    setEndTime?: (string) => void
    enableCustomPreset?: boolean
    size?: 'large' | 'medium'
}

const messages = defineMessages({
    customRangeLabel: {
        defaultMessage: 'Custom date range',
        description: 'Label for button which will select a custom date range.'
    }
})

function formatDateString(date: Date) {
    if (date) {
        const monthStr =
            (Number(date.getMonth()) < 9 ? '0' : '') + (date.getMonth() + 1)
        const dateStr =
            (Number(date.getDate()) < 10 ? '0' : '') + date.getDate()
        return date.getFullYear() + '-' + monthStr + '-' + dateStr
    } else {
        return date
    }
}

function formatTimeString(date: Date) {
    if (date) {
        const hoursStr =
            (Number(date.getHours()) < 10 ? '0' : '') + date.getHours()
        const minutesStr =
            (Number(date.getMinutes()) < 10 ? '0' : '') + date.getMinutes()
        return hoursStr + ':' + minutesStr
    } else {
        return date
    }
}

export const LeafDateRangeToggle: React.FC<LeafDateRangeToggleProps> = ({
    presets = leafDateRangePickerPresets,
    selectedPreset,
    setSelectedPreset,
    setStartDate,
    setEndDate,
    setStartTime = (s) => {},
    setEndTime = (s) => {},
    orientation = 'horizontal',
    color = 'primary',
    enableCustomPreset = false,
    sx,
    disabled,
    size
}) => {
    const intl = useIntl()

    const handleToggleChange = (event, selectedValue) => {
        setSelectedPreset(selectedValue)
        if (selectedValue !== null) {
            const today = Number(Date.now())
            const dateRange: LeafDateRangeProps =
                selectedValue.dateRangeCalculator(today)
            setStartDate(formatDateString(dateRange.startDate))
            setEndDate(formatDateString(dateRange.endDate))
            setStartTime(formatTimeString(dateRange.startDate))
            setEndTime(formatTimeString(dateRange.endDate))
        }
    }

    const customRangePreset = {
        displayName: messages.customRangeLabel,
        dateRangeCalculator: (currentDate) => {
            return {
                startDate: null,
                endDate: null
            }
        },
        isCustom: true
    }

    useEffect(() => {
        if (
            enableCustomPreset &&
            presets.find((p) => p.isCustom) === undefined
        ) {
            presets.push(customRangePreset)
        }
    }, [])

    return (
        <ToggleButtonGroup
            fullWidth
            value={selectedPreset}
            exclusive
            onChange={handleToggleChange}
            orientation={orientation}
            color={color}
            size={size}
            disabled={disabled}
            sx={{
                ...sx,
                '.MuiToggleButton-root': {
                    borderRadius: 1
                }
            }}
        >
            {presets.map((preset, key) => (
                <LeafToggleButton
                    key={key}
                    value={preset}
                    sx={{
                        textTransform: 'unset',
                        justifyContent:
                            orientation === 'vertical' ? 'start' : 'center'
                    }}
                >
                    <CoreTypography
                        customVariant={
                            size === 'large' ? 'buttonLarge' : 'buttonNormal'
                        }
                    >
                        {intl.formatMessage(preset.displayName)}
                    </CoreTypography>
                </LeafToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}
