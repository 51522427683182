import {
    StreamPlayerApi,
    StreamProps,
    useStreamSDK
} from '@cloudflare/stream-react'
import { captureException } from '@thriveglobal/thrive-web-core'
import React, {
    MutableRefObject,
    useCallback,
    useLayoutEffect,
    useMemo
} from 'react'
import PlayerContainer from './PlayerContainer'
import useEvent from './useEvent'
import useIframePlayerSrc from './useIframePlayerSrc'
import useProperty from './useProperty'

export type IframePlayerProps = Omit<
    StreamProps,
    'adUrl' | 'onResize' | 'responsive' | 'streamRef'
>

function IframePlayer(
    props: IframePlayerProps,
    ref: MutableRefObject<StreamPlayerApi>
) {
    const {
        src,
        controls = false,
        muted = false,
        autoplay = false,
        loop = false,
        preload = 'metadata',
        primaryColor,
        height,
        width,
        currentTime = 0,
        volume = 1,
        className,
        title = 'Reset player',
        poster,
        defaultTextTrack,
        letterboxColor,
        startTime,
        onAbort,
        onCanPlay,
        onCanPlayThrough,
        onDurationChange,
        onEnded,
        onError,
        onLoadedData,
        onLoadedMetaData,
        onLoadStart,
        onPause,
        onPlay,
        onPlaying,
        onProgress,
        onRateChange,
        onSeeked,
        onSeeking,
        onStalled,
        onSuspend,
        onTimeUpdate,
        onVolumeChange,
        onWaiting,
        onStreamAdStart,
        onStreamAdEnd,
        onStreamAdTimeout
    } = props

    const iframeRef = React.useRef<HTMLIFrameElement>(null)
    const streamApiRef = React.useRef<StreamPlayerApi>()
    const Stream = useStreamSDK()

    useProperty('muted', streamApiRef, muted)
    useProperty('controls', streamApiRef, controls)
    useProperty('autoplay', streamApiRef, autoplay)
    useProperty('currentTime', streamApiRef, currentTime)
    useProperty('loop', streamApiRef, loop)
    useProperty('preload', streamApiRef, preload)
    useProperty('primaryColor', streamApiRef, primaryColor)
    useProperty('volume', streamApiRef, volume)
    useProperty('poster', streamApiRef, poster)
    useProperty('letterboxColor', streamApiRef, letterboxColor)

    const iframeSrc = useIframePlayerSrc(src, {
        muted,
        preload,
        loop,
        autoplay,
        controls,
        poster,
        primaryColor,
        letterboxColor,
        startTime,
        defaultTextTrack
    })

    const handleLoadStart = useCallback(
        async (event: Event) => {
            onLoadStart?.(event)

            if (streamApiRef.current && autoplay) {
                streamApiRef.current.muted = true
                await streamApiRef.current.play()
                streamApiRef.current.muted = muted
            }
        },
        [onLoadStart, autoplay, muted]
    )

    const handleError = useCallback(
        (e: CustomEvent) => {
            onError?.(e)

            captureException(
                new Error(`Reset video playback has failed due to an error`),
                {
                    errorDetails: e?.detail
                }
            )
        },

        [onError]
    )

    const { bindEvent: bindAbortEvent, unbindEvent: unbindAbortEvent } =
        useEvent('abort', streamApiRef, onAbort)
    const { bindEvent: bindCanPlayEvent, unbindEvent: unbindCanPlayEvent } =
        useEvent('canplay', streamApiRef, onCanPlay)
    const {
        bindEvent: bindCanPlayThroughEvent,
        unbindEvent: unbindCanPlayThroughEvent
    } = useEvent('canplaythrough', streamApiRef, onCanPlayThrough)
    const {
        bindEvent: bindDurationChangeEvent,
        unbindEvent: unbindDurationChangeEvent
    } = useEvent('durationchange', streamApiRef, onDurationChange)
    const { bindEvent: bindEndedEvent, unbindEvent: unbindEndedEvent } =
        useEvent('ended', streamApiRef, onEnded)
    const { bindEvent: bindErrorEvent, unbindEvent: unbindErrorEvent } =
        useEvent('error', streamApiRef, handleError)
    const {
        bindEvent: bindLoadedDataEvent,
        unbindEvent: unbindLoadedDataEvent
    } = useEvent('loadeddata', streamApiRef, onLoadedData)
    const {
        bindEvent: bindLoadedMetaDataEvent,
        unbindEvent: unbindLoadedMetaDataEvent
    } = useEvent('loadedmetadata', streamApiRef, onLoadedMetaData)
    const { bindEvent: bindPauseEvent, unbindEvent: unbindPauseEvent } =
        useEvent('pause', streamApiRef, onPause)
    const { bindEvent: bindPlayEvent, unbindEvent: unbindPlayEvent } = useEvent(
        'play',
        streamApiRef,
        onPlay
    )
    const { bindEvent: bindPlayingEvent, unbindEvent: unbindPlayingEvent } =
        useEvent('playing', streamApiRef, onPlaying)
    const { bindEvent: bindProgressEvent, unbindEvent: unbindProgressEvent } =
        useEvent('progress', streamApiRef, onProgress)
    const {
        bindEvent: bindRateChangeEvent,
        unbindEvent: unbindRateChangeEvent
    } = useEvent('ratechange', streamApiRef, onRateChange)
    const { bindEvent: bindSeekedEvent, unbindEvent: unbindSeekedEvent } =
        useEvent('seeked', streamApiRef, onSeeked)
    const { bindEvent: bindSeekingEvent, unbindEvent: unbindSeekingEvent } =
        useEvent('seeking', streamApiRef, onSeeking)
    const { bindEvent: bindStalledEvent, unbindEvent: unbindStalledEvent } =
        useEvent('stalled', streamApiRef, onStalled)
    const { bindEvent: bindSuspendEvent, unbindEvent: unbindSuspendEvent } =
        useEvent('suspend', streamApiRef, onSuspend)
    const {
        bindEvent: bindTimeUpdateEvent,
        unbindEvent: unbindTimeUpdateEvent
    } = useEvent('timeupdate', streamApiRef, onTimeUpdate)
    const {
        bindEvent: bindVolumeChangeEvent,
        unbindEvent: unbindVolumeChangeEvent
    } = useEvent('volumechange', streamApiRef, onVolumeChange)
    const { bindEvent: bindWaitingEvent, unbindEvent: unbindWaitingEvent } =
        useEvent('waiting', streamApiRef, onWaiting)
    const { bindEvent: bindLoadStartEvent, unbindEvent: unbindLoadStartEvent } =
        useEvent('loadstart', streamApiRef, handleLoadStart)
    const {
        bindEvent: bindStreamAdStartEvent,
        unbindEvent: unbindStreamAdStartEvent
    } = useEvent('stream-adstart', streamApiRef, onStreamAdStart)
    const {
        bindEvent: bindStreamAdStartEndEvent,
        unbindEvent: unbindStreamAdStartEndEvent
    } = useEvent('stream-adend', streamApiRef, onStreamAdEnd)
    const {
        bindEvent: bindStreamAdStartTimeoutEvent,
        unbindEvent: unbindStreamAdStartTimeoutEvent
    } = useEvent('stream-adtimeout', streamApiRef, onStreamAdTimeout)

    useLayoutEffect(() => {
        if (!iframeRef.current || !Stream) {
            return
        }

        const streamApi = Stream(iframeRef.current)
        streamApiRef.current = streamApi

        if (ref) {
            ref.current = streamApi
        }

        // manually bind events when streamApi is ready
        bindAbortEvent(streamApi, onAbort)
        bindCanPlayEvent(streamApi, onCanPlay)
        bindCanPlayThroughEvent(streamApi, onCanPlayThrough)
        bindDurationChangeEvent(streamApi, onDurationChange)
        bindEndedEvent(streamApi, onEnded)
        bindErrorEvent(streamApi, onError)
        bindLoadedDataEvent(streamApi, onLoadedData)
        bindLoadedMetaDataEvent(streamApi, onLoadedMetaData)
        bindLoadStartEvent(streamApi, handleLoadStart)
        bindPauseEvent(streamApi, onPause)
        bindPlayEvent(streamApi, onPlay)
        bindPlayingEvent(streamApi, onPlaying)
        bindProgressEvent(streamApi, onProgress)
        bindRateChangeEvent(streamApi, onRateChange)
        bindSeekedEvent(streamApi, onSeeked)
        bindSeekingEvent(streamApi, onSeeking)
        bindStalledEvent(streamApi, onStalled)
        bindSuspendEvent(streamApi, onSuspend)
        bindTimeUpdateEvent(streamApi, onTimeUpdate)
        bindVolumeChangeEvent(streamApi, onVolumeChange)
        bindWaitingEvent(streamApi, onWaiting)
        bindStreamAdStartEvent(streamApi, onStreamAdStart)
        bindStreamAdStartEndEvent(streamApi, onStreamAdEnd)
        bindStreamAdStartTimeoutEvent(streamApi, onStreamAdTimeout)

        return () => {
            unbindAbortEvent(streamApi)
            unbindCanPlayEvent(streamApi)
            unbindCanPlayThroughEvent(streamApi)
            unbindDurationChangeEvent(streamApi)
            unbindEndedEvent(streamApi)
            unbindErrorEvent(streamApi)
            unbindLoadedDataEvent(streamApi)
            unbindLoadedMetaDataEvent(streamApi)
            unbindLoadStartEvent(streamApi)
            unbindPauseEvent(streamApi)
            unbindPlayEvent(streamApi)
            unbindPlayingEvent(streamApi)
            unbindProgressEvent(streamApi)
            unbindRateChangeEvent(streamApi)
            unbindSeekedEvent(streamApi)
            unbindSeekingEvent(streamApi)
            unbindStalledEvent(streamApi)
            unbindSuspendEvent(streamApi)
            unbindTimeUpdateEvent(streamApi)
            unbindVolumeChangeEvent(streamApi)
            unbindWaitingEvent(streamApi)
            unbindStreamAdStartEvent(streamApi)
            unbindStreamAdStartEndEvent(streamApi)
            unbindStreamAdStartTimeoutEvent(streamApi)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Stream]) // ignore all props except Stream to initialize Stream only once. Use useEvent and useProperty hooks to update props / subscribe to events

    const iframeStyle = useMemo(() => {
        return {
            height: '100%',
            width: '100%'
        }
    }, [])

    return (
        <PlayerContainer className={className}>
            <iframe
                ref={iframeRef}
                src={iframeSrc}
                title={title}
                frameBorder={0}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
                height={height}
                width={width}
                style={iframeStyle}
            />
        </PlayerContainer>
    )
}

export default React.memo(
    React.forwardRef<StreamPlayerApi, IframePlayerProps>(IframePlayer)
)
