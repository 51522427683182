import { Tab, TabProps, Typography } from '@mui/material'
import { useTheme } from '../../../../hooks'

interface LeafTabProps extends TabProps {
    component?: React.ElementType
}

export const CoreTab: React.FC<LeafTabProps> = ({ component, ...props }) => {
    const theme = useTheme()
    return (
        <Tab
            {...props}
            label={
                <Typography variant="h6" component={component}>
                    {props.label}
                </Typography>
            }
            sx={{
                color: 'grey.600',
                '.Mui-selected': {
                    color: 'primary.main',
                    border: 'none'
                },
                px: '.8rem',
                minWidth: 'unset',
                mr: '4rem',
                [theme.breakpoints.down('md')]: {
                    mr: '2rem'
                }
            }}
        />
    )
}

export default CoreTab
