import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ButtonProps } from '@mui/material'
import { IconButton } from '../IconButton'
import LeafIcon from '../../../foundations/icons'

const messages = defineMessages({
    closeIconButtonAriaLabel: {
        defaultMessage: 'Close',
        description:
            "Description of a 'close button' for the visually impaired."
    }
})

export const LeafCloseButton: React.FC<ButtonProps> = ({ ...props }) => {
    const intl = useIntl()
    return (
        <IconButton
            color="secondary"
            variant="contained"
            aria-label={intl.formatMessage(messages.closeIconButtonAriaLabel)}
            {...props}
        >
            <LeafIcon icon={'xmark'} />
        </IconButton>
    )
}

export default LeafCloseButton
