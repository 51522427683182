import { Chip, ChipProps } from '@mui/material'
import CoreTypography from '../../CoreTypography'
import LeafIcon from '../../../foundations/icons'
import React from 'react'

export interface LeafChipProps extends ChipProps {
    selectable?: boolean
    selected?: boolean
}

const LeafChip: React.FC<LeafChipProps> = ({
    selectable = false,
    selected = false,
    ...props
}) => {
    const { sx, icon, variant, label, ...otherProps } = props

    return (
        <Chip
            variant={selectable ? (selected ? 'filled' : 'outlined') : variant}
            icon={
                selectable ? (
                    selected ? (
                        <LeafIcon icon={'check'} fontSize={'small'} />
                    ) : (
                        <></>
                    )
                ) : (
                    icon
                )
            }
            label={
                <CoreTypography variant={'overline'}>{label}</CoreTypography>
            }
            {...otherProps}
            sx={{
                ...sx
            }}
        />
    )
}

export default LeafChip
