export type CopyToClipboardOptions = {
    container?: HTMLElement
}

export const TOOLTIP_TIMEOUT = 3000

export const copyTextToClipboardFallback = (
    text: string,
    options?: CopyToClipboardOptions
): boolean => {
    // MUI locks focus inside popups. Set disableEnforceFocus={true} or specify container inside popup to make textArea focusable.
    const container = options?.container ?? document.body

    const textArea = document.createElement('textarea')

    textArea.value = text

    textArea.style.opacity = '0'
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'
    textArea.style.zIndex = '999999'

    container.appendChild(textArea)

    const focusElement =
        document.activeElement !== document.body
            ? (document.activeElement as unknown as HTMLOrSVGElement)
            : null

    try {
        textArea.focus()
        textArea.select()
        return document.execCommand('copy')
    } catch {
        return false
    } finally {
        container.removeChild(textArea)
        focusElement?.focus?.()
    }
}

export const copyTextToClipboard = async (
    text: string,
    options?: CopyToClipboardOptions
): Promise<void> => {
    try {
        await window.navigator.clipboard.writeText(text)
    } catch {
        const isCopied = copyTextToClipboardFallback(text, options)
        return isCopied ? Promise.resolve() : Promise.reject()
    }
}
