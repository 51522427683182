import React, { ReactElement } from 'react'
import {
    Card,
    CardActions,
    CardActionArea,
    CardActionAreaProps,
    CardContent,
    CardMedia,
    CardProps,
    Typography,
    Stack,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '../../../../hooks'

interface LeafInlineCardProps extends Omit<CardProps, 'title'> {
    actionAreaProps?: CardActionAreaProps
    title?: string | ReactElement
    media?: ReactElement
    subtitle?: string | ReactElement
    action?: ReactElement
    responsiveDirection?: 'row' | 'column'
}

const LeafInlineCard: React.FC<LeafInlineCardProps> = ({
    media,
    title,
    subtitle,
    actionAreaProps,
    action,
    responsiveDirection = 'row',
    ...props
}) => {
    const { children, sx, ...otherCardProps } = props
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))

    const isClickable = action === undefined

    const InlineCardTitle = () => {
        if (typeof title === 'string') {
            return (
                <Typography variant="h4" color="primary.main">
                    {title}
                </Typography>
            )
        } else {
            return title
        }
    }

    const InlineCardSubtitle = () => {
        if (typeof subtitle === 'string') {
            return (
                <Typography variant="caption" color="gray[700]">
                    {subtitle}
                </Typography>
            )
        } else {
            return subtitle
        }
    }

    return (
        <Card
            {...otherCardProps}
            sx={{
                minWidth: 275,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: responsiveDirection,
                    alignItems:
                        responsiveDirection === 'column' ? 'left' : 'center',
                    '.MuiCardActions-root': {
                        alignSelf:
                            responsiveDirection === 'column'
                                ? 'flex-start'
                                : 'inherit'
                    }
                },
                '.MuiCardMedia-root': {
                    padding: 2,
                    display: 'flex'
                },
                '.MuiCardContent-root': {
                    '> *': {
                        display: 'block'
                    }
                }
            }}
        >
            {isClickable ? (
                <CardActionArea {...actionAreaProps}>
                    <Stack
                        direction={smDown ? responsiveDirection : 'row'}
                        spacing={0}
                    >
                        {media && <CardMedia>{media}</CardMedia>}
                        <CardContent>
                            <InlineCardTitle />
                            <InlineCardSubtitle />
                        </CardContent>
                    </Stack>
                </CardActionArea>
            ) : (
                <>
                    <Stack
                        direction={smDown ? responsiveDirection : 'row'}
                        spacing={0}
                    >
                        {media && <CardMedia>{media}</CardMedia>}
                        <CardContent>
                            <InlineCardTitle />
                            <InlineCardSubtitle />
                        </CardContent>
                    </Stack>
                    <CardActions>{action}</CardActions>
                </>
            )}
        </Card>
    )
}

export default LeafInlineCard
