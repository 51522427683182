import { SvgIcon } from '@mui/material'
import { motion } from 'framer-motion'
import { AnimationControls } from 'framer-motion/types/animation/types'
import React from 'react'

export interface BurstIconProps {
    animate: AnimationControls
    minHeight: string
    color: string
}

const BurstIcon: React.FC<BurstIconProps> = ({ animate, minHeight, color }) => {
    return (
        <SvgIcon
            aria-hidden="true"
            viewBox="0 0 68 68"
            sx={{
                position: 'absolute',
                height: minHeight,
                width: (theme) => theme.spacing(15),
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
            data-testid="BurstIcon"
        >
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="33.0015"
                y1="20"
                x2="33.0015"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="33.0015"
                y1="48"
                x2="33.0015"
                y2="68"
                stroke={color}
                strokeWidth="1"
            />
            <motion.path
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                d="M46.5 30L65.5211 23.8197"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="41.7788"
                y1="21.7836"
                x2="52.3589"
                y2="7.22131"
                stroke={color}
                strokeWidth="1"
            />
            <motion.path
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                d="M41.5 45L53.2557 61.1803"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="24.9478"
                y1="22.7682"
                x2="13.1921"
                y2="6.58786"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="19.712"
                y1="30.1315"
                x2="0.690848"
                y2="23.9511"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="20.3305"
                y1="39.9511"
                x2="1.30937"
                y2="46.1314"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="25.5659"
                y1="45.5878"
                x2="13.8101"
                y2="61.7681"
                stroke={color}
                strokeWidth="1"
            />
            <motion.line
                animate={animate}
                initial={{ pathLength: 0 }}
                strokeLinecap="round"
                x1="45.309"
                y1="40.0489"
                x2="64.3301"
                y2="46.2293"
                stroke={color}
                strokeWidth="1"
            />
        </SvgIcon>
    )
}

export default React.memo(BurstIcon)
