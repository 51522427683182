import { ToggleButton, ToggleButtonProps, styled } from '@mui/material'

export const LeafToggleButton: React.ComponentType<ToggleButtonProps> = styled(
    ToggleButton
)(({ theme }) => ({
    borderRadius: '25px',
    '&&.Mui-selected': {
        borderColor: 'transparent',
        '&&:focus-visible': {
            border: '2px solid blue'
        },
        // Primary
        '&&.MuiToggleButton-primary': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        // Secondary
        '&&.MuiToggleButton-secondary': {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        },
        // Success
        '&&.MuiToggleButton-success': {
            background: theme.palette.success.main,
            color: theme.palette.success.contrastText
        },
        // Info
        '&&.MuiToggleButton-info': {
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText
        },
        // Warning
        '&&.MuiToggleButton-warning': {
            background: theme.palette.warning.main,
            color: theme.palette.warning.contrastText
        },
        '&&.MuiToggleButton-root': {
            // Error
            '&&.Mui-error': {
                background: theme.palette.error.main,
                color: theme.palette.error.contrastText
            }
        }
    }
}))
