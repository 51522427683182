import { useState, ChangeEventHandler } from 'react'
import { Button, ButtonProps } from '@mui/material'

import { VisuallyHidden } from '../../../../utils/'
import { CoreTypography } from '../../CoreTypography'

export interface LeafFileUploadProps extends ButtonProps {
    label: string
    onChange?: ChangeEventHandler<HTMLInputElement>
}

export const LeafFileUpload: React.FC<LeafFileUploadProps> = ({
    label,
    onChange = () => {}
}) => (
    <Button component="label" variant="contained">
        <CoreTypography customVariant="buttonSmall">{label}</CoreTypography>

        <VisuallyHidden>
            <input type="file" onChange={onChange} />
        </VisuallyHidden>
    </Button>
)

export default LeafFileUpload
